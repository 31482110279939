// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import Rating from "../shared/Rating";
import { cartAddItem } from "../../store/cart";
import { compareRemoveItem, compareAddItem } from "../../store/compare";
import { url } from "../../services/utils";

// data stubs
import theme from "../../data/theme";
import { Close, Cross20Svg } from "../../svg";
import shopApi from "../../api/shop";
import { FormattedMessage } from "react-intl";

function ShopPageCompare(props) {
    const { products, compareRemoveItem, compareAddItem, locale } = props;

    const [check, setCheck] = useState();
    const arrId = [];
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: url.home() },
        { title: "Comparison", url: "" },
    ];

    // useEffect(() => {
    //     if (!products.length)
    //         setCheck('empty')
    // }, [products])
    useEffect(() => {
        if (products.length)
            products.forEach((prod) => {
                console.log(prod, "prod");
                shopApi.getProductBySlug(prod.url_key, { lang: locale }).then((e) => {
                    compareRemoveItem(prod.id).then((el) => {
                        compareAddItem(e.data, true);

                        setCheck("ok");
                    });
                });
            });
        else {
            setCheck("empty");
        }
    }, [locale]);

    let content;
    let attrName = [];
    let attribute = [];
    if (check == "ok") {
        const attributes = [];
        products.forEach((product) =>
            product.type == "configurable" && product.attributes
                ? product.attributes.forEach((productAttribute) => {
                      let attribute = attributes.find((x) => x.name === productAttribute.name);

                      if (!attribute) {
                          attribute = {
                              name: productAttribute.name,
                              values: {},
                          };
                          attributes.push(attribute);
                      }

                      // attribute.values[product.id] = productAttribute.values.map((x) => x.name).join(', ');
                  })
                : ""
        );

        products.forEach((e) => {
            for (let attrDis in e.group) {
                let fill = attribute.filter((el) => el == attrDis);
                if (!fill.length) attribute.push(attrDis);
            }
        });

        products.forEach((e) => {
            attribute.forEach((el) => {
                if (e?.group && e.group[el] && e.group[el].length) {
                    e.group[el].forEach((item) => {
                        if (item.length) {
                            let fill = attrName.filter((el) => el.value == item[0].admin_name);
                            if (!fill.length) attrName.push({ key: el, value: item[0].admin_name });
                        }
                    });
                }
            });
        });

        const productInfoRow = products.map((product) => {
            let image;
            if (product.images.length > 0) {
                image = (
                    <div className="compare-table__product-image product-image">
                        <div className="product-image__body">
                            <img
                                className="product-image__img_fm"
                                src={product.images[0].medium_image_url}
                                alt=""
                                width="100%"
                            />
                        </div>
                    </div>
                );
            }

            let renderButton = ({ run, loading }) => {
                const classes = classNames("remove-compare_fm", {
                    "btn-loading": loading,
                });

                return (
                    <button type="button" onClick={run} className={classes}>
                        <Close />
                    </button>
                );
            };

            return (
                <div key={product.id}>
                    <div key={product.id}>
                        <AsyncAction action={() => compareRemoveItem(product.id)} render={renderButton} />
                    </div>
                    <Link to={url.product(product)} className="compare-table__product-link ">
                        {image}
                        <div className="compare-table__product-name">{product.name}</div>
                        {console.log(product, "lllioa")}
                    </Link>
                </div>
            );
        });

        const ratingRow = products.map((product) => (
            <div key={product.id}>
                <div className="compare-table__product-rating">
                    <Rating value={product.rating} />
                </div>
                <div className=" compare-table__product-rating-legend">{`${product.reviews} Reviews`}</div>
            </div>
        ));

        const availabilityRow = products.map((product) => {
            let badge;

            if (product.availability === "in-stock") {
                badge = (
                    <span className="compare-table__product-badge badge badge-success">
                        <FormattedMessage id="in.stock" defaultMessage="In Stock" />
                    </span>
                );
            }

            return <div key={product.id}>{badge}</div>;
        });

        const priceRow = products.map((product) => (
            <div key={product.id}>
                <Currency value={product.price} />
            </div>
        ));

        const attributeRows = (
            <>
                {attribute.length
                    ? attribute.map((el) => (
                          <>
                              <div className="attr-title_fm">
                                  {/* {el} */}
                                  <FormattedMessage id={el.toLowerCase().replace(/ /g, ".")} defaultMessage={el} />
                              </div>
                              {attrName.length
                                  ? attrName.map((es) => {
                                        return el == es.key ? (
                                            <div className="attr-information_fm">
                                                <div>{es.value}</div>
                                                {products.map((e) => {
                                                    return e.group && e.group[el].length && e.group[el]
                                                        ? e.group[el].map((item) => {
                                                              return item.length && item[0].admin_name == es.value ? (
                                                                  <div>{item[0].name}</div>
                                                              ) : (
                                                                  ""
                                                              );
                                                          })
                                                        : "";
                                                })}
                                            </div>
                                        ) : (
                                            ""
                                        );
                                    })
                                  : ""}
                          </>
                      ))
                    : ""}
            </>
        );
        // = attributes.map((feature, index) => {
        //     const rows = products.map((product) => (
        //         <div key={product.id}>{feature.values[product.id]}</div>
        //     ));

        //     return (
        //         <div key={index}>
        //             <div>{feature.name}</div>
        //             {rows}
        //         </div>
        //     );
        // });

        content = (
            <div className="compare_fm">
                <div className="img-model_fm">
                    <div className="info_fm"></div>
                    {productInfoRow}
                </div>
                <div className="info-attr_fm">{attributeRows}</div>
            </div>
        );
    }
    if (check == "empty") {
        content = (
            <div className="block block-empty">
                <div className="container_fm">
                    <div className="block-empty__body">
                        <div className="block-empty__message">
                            <FormattedMessage
                                id="compareEmpty"
                                defaultMessage="You have not chosen any products to compare!"
                            />
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                <FormattedMessage id="continue" defaultMessage="Շարունակել" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Compare Products Page — ${theme.name}`}</title>
            </Helmet>
            <div className="container_fm">
                <h1 className="title_compare">
                    <FormattedMessage id="compare.products" defaultMessage="Համեմատել ապրանքները" />
                </h1>
                {content}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    products: state.compare,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartAddItem,
    compareRemoveItem,
    compareAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCompare);
