// react
import React from "react";

// third-party
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// application
import Pagination from "../shared/Pagination";

import theme from "../../data/theme";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { url } from "../../helper";
import BlockLoader from "../blocks/BlockLoader";
import { FormattedMessage } from "react-intl";

const AccountPageOrders = () => {
    const customer = useSelector((state) => state.customer);
    const [page, setPage] = useState(1);
    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (customer.token) {
            let urlPage = "";
            if (page) {
                urlPage = "&page=" + page;
            }
            fetch(url + `/api/orders?customer_id=${customer.customerId}&token=${customer.token}` + urlPage)
                .then((response) => response.json())

                .then((res) => {
                    setOrders(res);
                    setLoading(false);
                });
        }
    }, [customer.token, page]);

    const handlePageChange = (page) => {
        setPage(page);
        setLoading(true);
    };

    if (!orders || loading) {
        return <BlockLoader />;
    }

    //let arr = [{id: 1, created_at: "15.02.2011", status: "in-stok", formated_grand_total: 1500, name: 'Apple iPhone 12 mini'}]

    const ordersList = orders.data.map((order) => (
        <tr key={order.id}>
            <td>{`#${order.id}`}</td>
            {/* <td>{order.sku}</td> */}
            <td>{new Date(order.created_at).toLocaleString().slice(0, -3)}</td>
            <td>{order.formated_grand_total}</td>
            <td>{order.status}</td>
        </tr>
    ));

    return (
        <div>
            <Helmet>
                <title>{`Order History — ${theme.name}`}</title>
            </Helmet>
            <div className="card-table">
                <div className="table-responsive-sm">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="order" defaultMessage="ID" />
                                </th>
                                {/* <th><FormattedMessage id="order" defaultMessage="Ապրանք" /></th> */}
                                <th>
                                    <FormattedMessage id="date" defaultMessage="Ամսաթիվ" />
                                </th>
                                <th>
                                    <FormattedMessage id="total" defaultMessage="Ստատուս" />
                                </th>
                                <th>
                                    <FormattedMessage id="status" defaultMessage="Գին" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{ordersList}</tbody>
                    </table>
                </div>
            </div>
            <div className="card-divider" />
            <div className="card-footer">
                {orders.data.length > 0 ? (
                    <Pagination current={page} total={orders.data.length} onPageChange={handlePageChange} />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
    // }
};

export default AccountPageOrders;
