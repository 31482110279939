// react
import React, { useEffect, useState } from "react";

// third-party
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import StroykaSlick from "../shared/StroykaSlick";
import { Prev, Next } from "../../svg";
import { url } from "../../helper";

export default function Partner() {
    const [slickRef, setSlickRefs] = useState("");
    const [dep, setDep] = useState([]);
    const slickSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        fetch(`${url}/api/categories`)
            .then((response) => response.json())
            .then((res) => {
                setDep(res.categories[0].children);
            });
    }, []);

    const handleNextClick = () => {
        if (slickRef) {
            slickRef.slickNext();
        }
    };

    const handlePrevClick = () => {
        if (slickRef) {
            slickRef.slickPrev();
        }
    };

    const setSlickRef = (ref) => {
        setSlickRefs(ref);
    };

    let catList = dep.map((list, index) => (
        <div key={index} className="block-cat__item">
            <Link to={`/shop/catalog/${list.slug}`} className="shop-castalog_fms shop-cat_fms">
                <img src={url + "/storage/" + list.image_url} alt="" className="slider-img-fms" />
                <span className="slider-span-fms">
                    <FormattedMessage id={list.slug} defaultMessage={list.name} />
                </span>
            </Link>
        </div>
    ));

    return (
        <div className="container_fm">
            {/* <p className="block-header__title__custom_fm"><FormattedMessage id="products" defaultMessage="Ապրանքանիշներ" /></p> */}
            <div className="shops-cat-fms">
                <div className="partner_fms ip">
                    <div className="slider_button_fms  fm_next">
                        <span onClick={() => handleNextClick()}>
                            <Next />
                        </span>
                    </div>
                    <div className="slider_button_fms fm_prev">
                        <span onClick={() => handlePrevClick()}>
                            <Prev />
                        </span>
                    </div>
                    <StroykaSlick ref={setSlickRef} {...slickSettings}>
                        {catList ? catList : null}
                    </StroykaSlick>
                </div>
            </div>
        </div>
    );
}

const brandsList = [1, 2, 3, 4, 5, 2, 4, 1, 3].map((brand, index) => (
    <div key={index} className="block-brands__item">
        <Link to="/">
            <img src={`images/${brand}.png`} alt="" />
        </Link>
    </div>
));

// return (
//     <div className="container_fm">
//         <p className="block-header__title__custom_fm"><FormattedMessage id="products" defaultMessage="Ապրանքանիշներ" /></p>
//         <div className="partner_fms">
//             <div className="slider_button_fms">
//                 <span onClick={() => handlePrevClick()}><Prev/></span>
//                 <span onClick={() => handleNextClick()}><Next/></span>
//             </div>
//             <StroykaSlick
//             ref={setSlickRef}
//             {...slickSettings}>
//                 {brandsList}
//             </StroykaSlick>
//         </div>
//     </div>
// );
// }
