// react
import React, { useEffect, useMemo, useReducer, useState } from "react";

// third-party
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import Collapse from "../shared/Collapse";
import FilterCheck from "../filters/FilterCheck";

// application
import BlockLoader from "../blocks/BlockLoader";
import CategorySidebar from "./CategorySidebar";
import CategorySidebarItem from "./CategorySidebarItem";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import shopApi from "../../api/shop";
import WidgetFilters from "../widgets/WidgetFilters";
import WidgetProducts from "../widgets/WidgetProducts";
import WidgetCategories from "../widgets/WidgetCategories";
import { sidebarClose } from "../../store/sidebar";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ArrowDown, Check9x7Svg } from "../../svg";
import { setInitialMinPrice, setInitialMaxPrice } from '../../store/general/generalActions';

// data stubs
import theme from "../../data/theme";
import { url, getCategoryParents } from "../../services/utils";

import { urlLink } from "../../helper";
import qs from "query-string";
function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.brand === "string") {
        optionValues.brand = query.brand;
    }

    if (typeof query.savings === "string") {
        optionValues.savings = query.savings;
    }
    if (typeof query.category_id === "string") {
        optionValues.category_id =
            typeof query.category_id === "number" ? parseInt(query.category_id) : query.category_id;
    }
    if (typeof query.search === "string") {
        optionValues.search = query.search;
    }

    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    if (typeof query.order === "string") {
        optionValues.order = query.order;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.savings !== "") {
        params.savings = options.savings;
    }
    if (options.brand !== "") {
        params.brand = options.brand;
    }

    if (options.search !== "") {
        params.search = options.search;
    }
    params.category_id = options.category_id;
    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== "default") {
        params.sort = options.sort;
    }
    if (options.order !== "") {
        params.order = options.order;
    }
    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case "FETCH_CATEGORY_SUCCESS":
            return {
                ...state,
                init: true,
                categoryIsLoading: false,
                category: action.category,
            };
        case "FETCH_PRODUCTS_LIST":
            return { ...state, productsListIsLoading: true };
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return { ...state, productsListIsLoading: false, productsList: action.productsList };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: {
                    ...state.filters,
                    [action.filter]:
                        state.filters[action.filter] && action.filter !== "price"
                            ? state.filters[action.filter] + (action.value ? "," + action.value : "")
                            : action.value,
                },
            };

        case "REMOVE_FILTER_VALUE":
            let dot = state.filters[action.filter].split(",");
            const index = dot.indexOf(action.value);
            if (index > -1) {
                dot.splice(index, 1);
            }
            dot = dot.join(",");

            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: dot },
            };

        case "RESET_FILTERS":
            return { ...state, options: {}, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function ShopPageCategory(props) {
    // const { categorySlug, columns, viewMode, sidebarPosition } = props;
    const { categorySlug, columns, viewMode, sidebarPosition, initialMaxPrice, initialMinPrice, setInitialMinPrice, setInitialMaxPrice } = props;

    const urlI = window.location.search;

    let cat = qs.parse(urlI);

    const offcanvas = columns === 3 ? "mobile" : "always";
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const location = useLocation();
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [catName, setCatName] = useState(null);
    const [catID, setCatID] = useState();
    const [brands, setBrands] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const locale = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer);

    const [maxPrice, setMaxPrice] = useState();
    const [minPrice, setMinPrice] = useState();
    const [filtersData, setFilters] = useState();



    useEffect(() => {
        let canceled = false;
        shopApi.getCategories({ locale: locale }).then((categories) => {
            if (canceled) {
                return;
            }

            function getItems(array) {
                array.forEach((e, i) => {
                    if (e.slug == categorySlug) {
                        console.log(111111111111)
                        setCatID(e.id);
                        return false;
                    } else if (e.children?.length > 0) {
                        getItems(e.children)
                    }
                })
            }

            if (categories?.categories[0]?.children.length > 0) {
                getItems(categories.categories[0].children)
            }

            // if (categories.categories) {
            //     categories.categories[0].children.filter((e) => {
            //         if (e.slug === `${categorySlug}`.toLowerCase()) {
            //             setCatID(e.id);
            //             return false;
            //         } else {
            //             if (e.children.length) {
            //                 e.children.filter((elem) => {
            //                     if (elem.slug === `${categorySlug}`.toLowerCase()) {
            //                         setCatID(elem.id);
            //                         return false;
            //                     }
            //                 });
            //             }
            //         }
            //     });
            // }


            setCategories(categories.categories);
        });

        return () => {
            canceled = true;
        };
    }, [categorySlug, setCategories]);


    useEffect(() => {
        if (categories.length > 0) {
            const currentCat = categories[0].children.find((e) => e.id == cat.category_id);
            setCatName(currentCat ? currentCat.name : null);
        }
    }, [categories, cat.category_id, setCatName]);

    // useEffect(() => {
    //     let canceled = false;

    //     shopApi.getBrands({ locale: locale }).then((brands) => {
    //         if (canceled) {
    //             return;
    //         }
    //         console.log(brands,'ssssss')
    //         setBrands(brands.data);
    //     });

    //     return () => {
    //         canceled = true;
    //     };

    // }, [setBrands])
    // Replace current url.

    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;
        window.history.replaceState(null, "", location);
    }, [state.options, state.filters]);

    // Load products.
    useEffect(() => {
        dispatch({ type: "FETCH_PRODUCTS_LIST" });
        if (catID) {
            shopApi.getProductsList(state.options, { ...state.filters }, location.search, catID).then((productsList) => {
                // const maxPrice = productsList.data[productsList.data.length - 2].maxPrice;
                // const minPrice = productsList.data[productsList.data.length - 1].minPrice;
                // setMaxPrice(maxPrice);
                // setMinPrice(minPrice);
                // productsList.data.pop()
                // productsList.data.pop()

                dispatch({ type: "FETCH_PRODUCTS_LIST_SUCCESS", productsList: [productsList] });
            });
        }
    }, [dispatch, location.search, state.options, state.filters, catID]);




    useEffect(() => {
        let catFm = null;
        if (location.search) {
            catFm = location.search;
        } else {
            catFm = cat.category_id;
        }

        if (catID) {
            fetch(`${urlLink}/api/filters?category_id=${catID}`)
                .then((responce) => responce.json())
                .then((e) => {
                    if (e.data) {
                        setFilters(e.data.filter);
                        setInitialMaxPrice(e.data.max_price)
                        setInitialMinPrice(e.data.min_price)
                    } else {
                        setFilters(e.filter);
                    }
                });
        }


    }, [cat.category_id, location.search, categorySlug, catID]);

    if (state.productsListIsLoading && !state.productsList) {
        return <BlockLoader />;
    }
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: url.home() },
        // { title: <FormattedMessage id="shop" defaultMessage="Խանութ" />, url: url.catalog() },
        {
            title: catName ? <FormattedMessage id={catName.toLowerCase()} defaultMessage={catName} /> : "",
            url: "adfasd",
        },
    ];
    let pageTitle = "Shop";
    let content;

    const setSavings = (e, type) => {
        e.preventDefault();
        let inp = document.getElementById("savings_fm_id");
        if (type == 0) {
            if (inp.checked === true)
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: "",
                });
            else
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: true,
                });
        } else {
            if (inp.checked === false)
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: "",
                });
            else
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: true,
                });
        }
    };

    const productsView = (
        <ProductsView
            catID={catID}
            categorySlug={categorySlug}
            isLoading={state.productsListIsLoading}
            productsList={state.productsList}
            options={state.options}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
            offcanvas={offcanvas}
            customer={customer}
        />
    );

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                {/* <WidgetCategories
                    dispatch={dispatch}
                    categories={categories}
                    catID={queryString.parse(location.search).category_id || state.options.category_id}
                /> */}
                <div className={`widget-filters widget'`}>
                    <div className="widget__title title-arrow-flex" onClick={(e) => setIsOpen(!isOpen)}>
                        <FormattedMessage id="menu.savings" defaultMessage="Savings" />
                        <ArrowDown className={isOpen ? "d-block rott_fms" : "d-block"} />
                    </div>

                    <div className="filter-list__list" activeClassName="is-active">
                        <label className={isOpen ? "dropdown-group active filter-list__list" : "dropdown-group"}>
                            <div className="filter-list__input input-check">
                                <span className="filter-list__title">
                                    <FormattedMessage id="menu.savings" defaultMessage="Savings" />
                                </span>
                                <label className="input-check__body" onClick={(e) => setSavings(e, 1)}>
                                    <input
                                        id="savings_fm_id"
                                        className="input-check__input"
                                        type="checkbox"
                                        checked={state.options.savings ? true : false}
                                    // onChange={(e) => setSavings(e, 0)}
                                    />
                                    <span className="input-check__box" />
                                    <Check9x7Svg className="input-check__icon" />
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
                <WidgetFilters
                    // filters={brands[0].options}
                    //  test={state.productsList.filter}
                    filtersData={filtersData}
                    dispatch={dispatch}
                    stateFilters={state}
                    values={state.filters}
                    catID={catID}
                    maxPrice={maxPrice}
                    minPrice={minPrice}
                    initialMaxPrice={initialMaxPrice}
                    initialMinPrice={initialMinPrice}
                />
            </CategorySidebarItem>
        </CategorySidebar>
    );

    if (columns > 3) {
        content = (
            <div className="container_fm">
                <div className="block">{productsView}</div>
                {sidebarComponent}
            </div>
        );
    } else {
        const sidebar = <div className="shop-layout__sidebar">{sidebarComponent}</div>;

        content = (
            <div className="container_fm">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }

    const change = () => {
        return 456;
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Shop Category Page — ${theme.name}`}</title>
                <meta name="keywords" content={categorySlug || 'Category'} />
                <meta name="description" content={categorySlug || 'Category'} />
                <meta property="og:title" content={theme.name} />
                <meta property="og:description" content={categorySlug || 'Category'} />
                <meta property="og:image" content="https://api.likemobile.am/cache/medium/product/3749/OcqBqXgksS3TdSNtbI5Ow8EjMhUp7H5k8u1BJSPV.jpg" />
            </Helmet>
            <div className="cat_blocks_fms">
                <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

                {content}
            </div>
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => {
    return ({
        sidebarState: state.sidebar,
        page: state.category,
        initialMaxPrice: state.general.initialMaxPrice,
        initialMinPrice: state.general.initialMinPrice,
    })
}

const mapDispatchToProps = (dispatch) => ({
    sidebarClose: (payload) => dispatch(sidebarClose(payload)),
    setInitialMinPrice: (payload) => dispatch(setInitialMinPrice(payload)),
    setInitialMaxPrice: (payload) => dispatch(setInitialMaxPrice(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
