// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import theme from "../../data/theme";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { url } from "../../helper";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function AccountPageProfile() {
    const customer = useSelector((state) => state.customer);
    const [address, setAddress] = useState();
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState(false);
    const [successData, setSuucessData] = useState();
    const [input, setInput] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        input[e.target.name] = e.target.value;
        setInput(input);
    };
    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;
        if (customer.token) {
            fetch(url + "/api/customer/get?token=" + customer.token, { single: single })
                .then((responce) => responce.json())
                .then((res) => {
                    if (res) {
                        setAddress(res.data);
                    }
                })
                .catch((err) => console.error(err));
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [customer.token]);

    if (!address) {
        return "";
    }
    const handleClick = (event) => {
        event.preventDefault();
        setLoading(true);
        let option = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                token: customer.token,
                first_name: input.first_name !== undefined ? input.first_name : address.first_name,
                last_name: input.last_name !== undefined ? input.last_name : address.last_name,
                email: input.email !== undefined ? input.email : address.email,
                phone: input.phone !== undefined ? input.phone : address.phone,
                gender: "gender",
                date_of_birth: null,
            }),
        };
        fetch(`${url}/api/customer/profile`, option)
            .then((response) => response.json())
            .then((res) => {
                setLoading(false);
                if (res.errors) {
                    console.log(res.errors);
                    setErrors(res.errors);
                } else {
                    setSuccess(true);
                    setSuucessData(res.message);
                }
            });
    };

    const ErrorsOutput = () => {
        return errors;
    };
    return (
        <div>
            
            {success ? <div className="alert alert-success">{successData}</div> : ""}
            <div className="form-row no-gutters  justify-content-between">
                <div className="form-group col-md-6">
                    <label htmlFor="profile-first-name" className="profile-label-fm">
                        <FormattedMessage id="name" defaultMessage="Անուն" />
                    </label>
                    <input
                        id="profile-first-name"
                        name="first_name"
                        onChange={handleChange}
                        defaultValue={address ? address.first_name : ""}
                        value={input.first_name}
                        type="text"
                        className="form-control"
                    />
                    {errors && errors.first_name && errors.first_name.length > 0 && (
                        <span className="alert-danger">{errors.first_name[0]}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="profile-last-name" className="profile-label-fm">
                        <FormattedMessage id="lname" defaultMessage="Ազգանուն" />{" "}
                    </label>
                    <input
                        id="profile-last-name"
                        name="last_name"
                        onChange={handleChange}
                        defaultValue={address ? address.last_name : ""}
                        value={input.last_name}
                        type="text"
                        className="form-control"
                    />
                    {errors && errors.last_name && errors.last_name.length > 0 && (
                        <span className="alert-danger">{errors.last_name[0]}</span>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="profile-email" className="profile-label-fm">
                        <FormattedMessage id="email" defaultMessage="Էլ․ հասցե" />
                    </label>
                    <input
                        onChange={handleChange}
                        name="email"
                        defaultValue={address ? address.email : ""}
                        value={input.email}
                        id="profile-email"
                        type="email"
                        className="form-control"
                    />
                    {errors && errors.email && errors.email.length > 0 && (
                        <span className="alert-danger">{errors.email[0]}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="profile-phone" className="profile-label-fm">
                        <FormattedMessage id="phone" defaultMessage="Հեռախոսահամար" />
                    </label>
                    <input
                        onChange={handleChange}
                        defaultValue={address ? address.phone : ""}
                        value={input.phone}
                        name="phone"
                        id="profile-phone"
                        type="text"
                        className="form-control"
                    />
                    {errors && errors.phone && errors.phone.length > 0 && (
                        <span className="alert-danger">{errors.phone[0]}</span>
                    )}
                </div>
            </div>
            <div className="form-group mt-1 mb-0 text-right">
                <button onClick={handleClick} type="button" className="save_profile_data_fm">
                    <FormattedMessage id="save" defaultMessage="Պահպանել" />
                </button>
            </div>
        </div>
    );
}
