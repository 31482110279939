import { SET_SEARCH_WORD } from './searchActionTypes';

const initialState = {
    searchWord: "",
};

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_WORD:
            return {
                searchWord: action.payload
            };
        default:
            return state
    }
}
