
// react
import React  from 'react';

import { useHistory } from 'react-router-dom';
import Login  from './Login'
import ForgotPassword  from './ForgotPassword'
import Register  from './Register'

export default function LoginBlock(props) { 

    return (
        <div>     
            {props.login ?
                <div>
                    <Login close={props.close} clicked={props.clicked} closeModal={props.closeModal}/>
                </div>
                : ''}
            {props.forgot ?
                <div>
                    <ForgotPassword close={props.close} clicked={props.clicked} closeModal={props.closeModal}/>
                </div>
                : ''}
            {props.reg ?
                <div>
                    <Register  close={props.close} clicked={props.clicked} reg={props.reg}/>
                </div>

                : ''}
        </div>
    );
}
