// react
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { url } from '../../helper'
import { ArrowDown } from '../../svg';



export default function FooterNewsletter(props) {
    return (
        <div className="del_fms">
            <div className="footer_contact_text_fms" onClick={() => props.size < 768? props.openFunc('shipp'): ''}>
                <FormattedMessage id="foot.delivery" defaultMessage="Առաքում" />
                <ArrowDown className={props.open? "d-block d-md-none rott_fms": "d-block d-md-none"}/>
            </div>
            {props.open ?
                <ul className="footer-contacts__contacts">
                    <li className="l-h">
                        <FormattedMessage id="footShipp.address" defaultMessage="Առաքումն իրականացվում է 1 աշխատանքային օրվա ընթացքում 10։00-20։009" />
                    </li>
                </ul> : ''}



        </div>
    );
}
