// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities } from "../../store/cart";
import { Cross12Svg, BackArrow } from "../../svg";
import { url } from "../../services/utils";
import { urlLink } from "../../helper";
// data stubs
import theme from "../../data/theme";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            cartToken: this.props.cartToken,
            customer: this.props.customer,
            backUrl: document.referrer,
        };
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);
        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity, cartItem) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity, cartItem: cartItem });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }

    renderItems() {
        const { cart, cartRemoveItem } = this.props;
        return cart.items.map((item, index) => {
            let image;
            let options;
            let id;

            if (item.product.images) {
                if (item.product.images[0].medium_image_url) {
                    console.log(item.product.images[0]);
                    image = (
                        <div className="product-image">
                            <Link to={url.product(item.product)} className="product-image__body">
                                <img
                                    className="product-image__img"
                                    src={item.product.images[0].medium_image_url}
                                    alt=""
                                />
                            </Link>
                        </div>
                    );
                } else {
                    image = (
                        <div className="product-image">
                            <Link to={url.product(item.product)} className="product-image__body">
                                <img
                                    className="product-image__img"
                                    src={`${urlLink}/cache/medium/` + item.product.images[0]}
                                    alt=""
                                />
                            </Link>
                        </div>
                    );
                }
                // id = cart.cartItems[index].cartItemId;
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() =>
                        cartRemoveItem(
                            item.itemId,
                            item.id,
                            this.state.cartToken.cartToken,
                            this.state.customer.token ? this.state.customer.token : null
                        )
                    }
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    {/* <td className="cart-table__column cart-table__column--image">
                    </td> */}
                    <td className="cart-table__column cart-table__column--image">
                        <div className="cart_info_fm">
                            {image}
                            <Link to={url.product(item.product)} className="cart-table__product-name">
                                {item.product.name}
                            </Link>
                            {options}
                        </div>
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={parseInt(item.price)} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity, cart.items[index].itemId)}
                            value={this.getItemQuantity(item)}
                            min={1}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={parseInt(item.total)} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">{removeButton}</td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={parseInt(cart.subtotal)} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body"></tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, cartUpdateQuantities } = this.props;
        const { quantities, backUrl } = this.state;

        const updateCartButton = (
            <AsyncAction
                action={() =>
                    cartUpdateQuantities(quantities, cart.cartItems, this.state.customer, this.state.cartToken)
                }
                render={({ run, loading }) => {
                    const classes = classNames("cart__update-button", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes} disabled={!this.cartNeedUpdate()}>
                            <FormattedMessage id="updateCart" defaultMessage="Թարմացնել զամբյուղը" />
                        </button>
                    );
                }}
            />
        );

        return (
            <div className="container_fm cart_fms">
                <div className="cart_block_fm">
                    <div className="title_account_fms">
                        <FormattedMessage id="cartTitle" defaultMessage="Զամբյուղ" />
                    </div>
                    <table className="cart__table cart-table  d-none d-md-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--product">
                                    <FormattedMessage id="product" defaultMessage="Ապրանք" />
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    <FormattedMessage id="price" defaultMessage="Գին" />
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    <FormattedMessage id="qty" defaultMessage="Քանակ" />
                                </th>
                                <th className="cart-table__column cart-table__column--total">
                                    <FormattedMessage id="total" defaultMessage="Ընդհանուր" />
                                </th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>
                    <div className="cart_table_fm d-block d-md-none">
                        <div className="cart_mob_tab_fm">
                            {cart.items?.map((e, i) => (
                                <div className="cart_mob_blok_fm" key={i}>
                                    <div className="cart_mob_title_fm">
                                        <div>
                                            <FormattedMessage id="global.product" defaultMessage="Product" />
                                        </div>
                                        <div>
                                            <AsyncAction
                                                action={() => {
                                                    return cartRemoveItem(
                                                        e.itemId,
                                                        e.id,
                                                        this.state.cartToken.cartToken,
                                                        this.state.customer.token ? this.state.customer.token : null
                                                    );
                                                }}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        className={`btn_cart_rem_fm ${classNames({
                                                            "btn-loading": loading,
                                                        })}`}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <g
                                                                id="Group_68556"
                                                                data-name="Group 68556"
                                                                transform="translate(-390 -342)"
                                                            >
                                                                <rect
                                                                    id="Rectangle_148836"
                                                                    data-name="Rectangle 148836"
                                                                    width="24"
                                                                    height="24"
                                                                    transform="translate(390 342)"
                                                                    fill="#fff"
                                                                />
                                                                <path
                                                                    id="close"
                                                                    d="M9.738,8.366l6.409-6.409A1.066,1.066,0,1,0,14.639.449L8.23,6.858,1.821.449A1.066,1.066,0,1,0,.313,1.957L6.722,8.366.313,14.775a1.066,1.066,0,1,0,1.508,1.508L8.23,9.874l6.409,6.409a1.066,1.066,0,0,0,1.508-1.508Zm0,0"
                                                                    transform="translate(393.77 345.863)"
                                                                    fill="#43b02a"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="cart_mob_prod_fm">
                                        <div
                                            onClick={() => {
                                                this.modalFunc(e.product);
                                            }}
                                        >
                                            <img
                                                src={`${urlLink}/cache/medium/` + e.product.images[0]}
                                                alt=""
                                                height="120px"
                                            />
                                        </div>
                                        <div
                                            onClick={() => {
                                                this.modalFunc(e.product);
                                            }}
                                        >
                                            {e.product.name}
                                        </div>
                                    </div>
                                    <div className="cart_mob_title_fm">
                                        <div>
                                            <FormattedMessage id="price" defaultMessage="Price" />
                                        </div>
                                        <div>
                                            {Math.round(e.price)} <FormattedMessage id="curency" defaultMessage="AMD" />
                                        </div>
                                    </div>
                                    <div className="cart_mob_title_fm">
                                        <div>
                                            <FormattedMessage id="quantity" defaultMessage="Quantity" />
                                        </div>
                                        <div>
                                            <InputNumber
                                                onChange={(quantity) =>
                                                    this.handleChangeQuantity(e, quantity, cart.items[i].itemId)
                                                }
                                                value={this.getItemQuantity(e)}
                                                min={1}
                                            />
                                        </div>
                                    </div>
                                    <div className="cart_mob_title_fm">
                                        <div>
                                            <FormattedMessage id="total" defaultMessage="Total" />
                                        </div>
                                        <div>
                                            {Math.round(e.total)} <FormattedMessage id="curency" defaultMessage="AMD" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <a className="cart_go_back_fm" href="/">
                        <BackArrow />
                        <FormattedMessage id="gotohomepage" defaultMessage="Գլխավոր էջ" />
                    </a>
                    <div className="cart__totals-footer">
                        <div>
                            <FormattedMessage id="totals" defaultMessage="Ընդհանուր`" />
                        </div>
                        <div>
                            <Currency value={parseInt(cart.total)} />
                        </div>
                    </div>
                    <div className="cart__actions">
                        <div className="cart__buttons">
                            {updateCartButton}
                            <Link to="/shop/checkout" className="ml-3 cart__checkout-button">
                                <FormattedMessage id="goToCheckout" defaultMessage="Վճարել" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;

        const breadcrumb = [
            { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: url.home() },
            { title: <FormattedMessage id="cart" defaultMessage="Զամբյուղ" />, url: "" },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">
                                {" "}
                                <FormattedMessage id="cartEmpty" defaultMessage="Ձեր զամբյուղը դատարկ է!" />
                            </div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    <FormattedMessage id="continue" defaultMessage="Շարունակել" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shopping Cart — ${theme.name}`}</title>
                </Helmet>

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    customer: state.customer,
    cartToken: state.cartToken,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
