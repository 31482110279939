// react
import React, { useCallback, useEffect, useState } from "react";

// third-party
import classNames from "classnames";
// application
import Collapse from "../shared/Collapse";
import { connect } from 'react-redux';

import { setInitialMinPrice, setInitialMaxPrice } from '../../store/general/generalActions';

import FilterCheck from "../filters/FilterCheck";

import FilterRange from "../filters/FilterRange";

import { ArrowRoundedDown12x7Svg } from "../../svg";

import { FormattedMessage } from "react-intl";
import { url } from "../../helper";
import FilterColor from "../filters/FilterColor";
import qs from "query-string";

const CheckFilterHandler = {
    type: "check",
    serialize: (value) => value.join(","),
    deserialize: (value) => (value ? value.split(",") : []),
    isDefaultValue: (filter, value) => value.length === 0,
    getDefaultValue: () => [],
};

function WidgetFilters(props) {
    const {
        filtersData,
        minPrice,
        maxPrice,
        stateFilters,
        dispatch,
        filters,
        values,
        title,
        offcanvas,
        initialMaxPrice,
        initialMinPrice,
        setInitialMinPrice,
        setInitialMaxPrice
    } = props;
    const urlI = window.location.search;
    let cat = qs.parse(urlI);

    const handleValueChange = useCallback(
        ({ filter, value, remove }) => {
            if (remove) {
                dispatch({
                    type: "REMOVE_FILTER_VALUE",
                    filter: filter,
                    value: value,
                });
            } else {
                dispatch({
                    type: "SET_FILTER_VALUE",
                    filter: filter,
                    value: value ? CheckFilterHandler.serialize(value) : "",
                });
            }
        },
        [dispatch]
    );

    const handleResetFilters = () => {
        setInitialMinPrice(initialMinPrice)
        setInitialMaxPrice(initialMaxPrice)
        dispatch({ type: "RESET_FILTERS" });


        // dispatch({
        //     type: "SET_FILTER_VALUE",
        //     filter: "category_id",
        //     value: Object.values(cat)[0],
        // });
    };

    const classes = classNames("widget-filters widget", {
        "widget-filters--offcanvas--always": offcanvas === "always",
        "widget-filters--offcanvas--mobile": offcanvas === "mobile",
    });

    const filtersList = (
        <>
            {filtersData
                ? filtersData.map((item, index) => {
                    if (item.code != "color") {
                        return (
                            <div className={classes}>
                                <div className="widget-filters__list">
                                    <FilterCheck
                                        filterValues={values}
                                        // checked={chekedValue}
                                        data={item.options}
                                        value={item}
                                        onChangeValue={handleValueChange}
                                        title={item.name}
                                    />
                                </div>
                            </div>
                        );
                    }
                })
                : ""}
        </>
    );

    const filtersColor = (
        <>
            {filtersData
                ? filtersData.map((item, index) => {
                    if (item.code == "color") {
                        if (index > 1) {
                            return false;
                        }
                        return (
                            <div className={classes}>
                                <div className="widget-filters__list">
                                    <FilterColor
                                        filterValues={values}
                                        // checked={chekedValue}
                                        data={item.options}
                                        value={item}
                                        onChangeValue={handleValueChange}
                                        title={
                                            <FormattedMessage
                                                id={item.name.toLowerCase().replace(/ /g, ".")}
                                                defaultMessage={item.name}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }

                    return false;
                })
                : ""}
        </>
    );

    return (
        <>
            {filtersList}
            {filtersColor}
            <div className={classes}>
                <div className="widget-filters__list">
                    <FilterRange
                        key={"Max Price"}
                        data={{ min: parseInt(initialMinPrice), max: parseInt(initialMaxPrice) }} // parseInt(maxPrice)
                        // value={stateFilters.filters.price !== undefined ? stateFilters.filters.price.split(",") : ""}
                        value={stateFilters.filters.price !== undefined ? stateFilters.filters.price.split(",") : [parseInt(minPrice), parseInt(maxPrice)]}
                        onChangeValue={handleValueChange}
                        title={"Գին"}
                    />
                </div>
                <div className="widget-filters__actions d-flex mb-n2">
                    <button type="button" className="btn btn-secondary btn-sm ml-2" onClick={handleResetFilters}>
                        <FormattedMessage id="reset" defaultMessage="Reset" />
                    </button>
                </div>
            </div>
            {/* <div className="widget-filters__actions d-flex mb-n2">
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={handleResetFilters}
                    >
                       <FormattedMessage id="reset" defaultMessage="Վերականգնել" />
                    </button>
                </div> */}
        </>
    );
}

WidgetFilters.defaultProps = {
    offcanvas: "mobile",
};

const mapStateToProps = (state) => {
    return ({
        initialMaxPrice: state.general.initialMaxPrice,
        initialMinPrice: state.general.initialMinPrice,
    })
}
const mapDispatchToProps = (dispatch) => ({
    setInitialMinPrice: (payload) => dispatch(setInitialMinPrice(payload)),
    setInitialMaxPrice: (payload) => dispatch(setInitialMaxPrice(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetFilters);
