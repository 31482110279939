// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPageProfile from './AccountPageProfile';
import AccountPageNewAddresses from './AccountPageNewAddresses';
import { FormattedMessage } from 'react-intl';
import {useSelector,useDispatch} from 'react-redux'
import { useHistory } from 'react-router-dom';
import {url} from '../../helper';
import { Address, List, Logout, User } from '../../svg';
import { cartAddItemSuccessAll } from '../../store/cart';
export default function AccountLayout(props) {
    const customer = useSelector(state => state.customer);
    const dispatch=useDispatch();
    const { match, location } = props;
    const history = useHistory();
    const logout=(e)=>{
        e.preventDefault()
        fetch(`${url}/api/customer/logout?token=${customer.token}`)
           .then(responce => responce.json())
           .then(res => {
               dispatch({ type: 'AUTHENTICATED', payload: false })
               dispatch({ type: 'CUSTOMER_TOKEN', payload: '' });
                dispatch({ type: 'CUSTOMER_NAME', payload: false });
               dispatch(cartAddItemSuccessAll([],0,0,0))
               history.push('/')
           })
     }
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: '' },
        { title:  <FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" /> , url: '' },
    ];
 
    const links = [
        { title: <FormattedMessage id="topbar.myAccount" defaultMessage="Անձնական տվյալներ" />,  icon: <User />  , url: 'dashboard' },
        // { title: <FormattedMessage id="account.editProfil" defaultMessage="Խմբագրել Հաշիվը" />,  icon: <User />    , url: 'profile' },
        { title: <FormattedMessage id="account.orderHistory" defaultMessage="Պատվերներ" />,  icon: <List />, url: 'orders' },
        { title: <FormattedMessage id="account.address " defaultMessage="Հասցե" />,  icon: <Address />, url: 'addresses' },
        { title: <FormattedMessage id="account.logout" defaultMessage="Դուրս գալ" />,  icon: <Logout />, url: 'logout' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });
  
        return (
            <li key={link.url} className={classes}>
                {link.url==='logout'? 
                    <div>{link.icon} <a href="/account/logout" onClick={ logout }><FormattedMessage id="account.logout" defaultMessage="Ելք" /></a></div>
               : <div>{link.icon} <Link  to={url}>{link.title}</Link></div>
                }
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header={breadcrumb[1].title} breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container_fm">
                    <div className="title_account_fms"><FormattedMessage id="myAccount" defaultMessage="Իմ Էջը" /></div>
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`}  component={AccountPageProfile} />                              
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/orders/:orderId`}
                                    render={(props) => (
                                    <AccountPageOrderDetails id={props.match.params}/>

                                   )} />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route exact path={`${match.path}/addresses/new`} component={AccountPageNewAddresses} />
                                <Route exact path={`${match.path}/addresses/:addressId`} 
                                 render={(props) => (
                                    <AccountPageEditAddress id={props.match.params}/>

                                 )} />                                          
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
