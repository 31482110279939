// react
import React, { useMemo, useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";

// application
import shopApi from "../../api/shop";

import BlockFeatures from "../blocks/BlockFeatures";

import BlockProducts from "../blocks/BlockProducts";

import BlockSlideShow from "../blocks/BlockSlideShow";
import { FormattedMessage } from "react-intl";
import BlockBanner from "../blocks/BlockBanner";

import theme from "../../data/theme";

import { useSelector } from "react-redux";
import Partner from "../blocks/Partner";
import { url } from "../../helper";
import BlockPosts from "../blocks/BlockPosts";

function HomePageOne() {
    const selectedData = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer);
    const [bestsellers, SetBest] = useState();
    const [limitProd, SetProd] = useState();
    const [note, SetNote] = useState();
    const [banner, GetBanner] = useState();
    // const [featured, Setfeatured] = useState();

    const bestSeller = <FormattedMessage id="best.sellers" defaultMessage="Best seller" />;
    const newproduct = <FormattedMessage id="new" defaultMessage="Նորույթ" />;
    const specialoffer = <FormattedMessage id="specialoffer" defaultMessage="Հատուկ առաջարկներ" />;

    useEffect(() => {
        shopApi.getBestSellers({ lang: selectedData, id: 12 }).then((res) => {
            SetProd(res[0].data);
        });
    }, []);
    useEffect(() => {
        shopApi.specialOffer({ lang: selectedData, id: 12 }).then((res) => {
            // const newData = res.data.filter((e) => {
            //     if (e.id) return e;
            // });
            // SetNote(newData);

            SetNote(res[0].data);
        });
        shopApi.getNewCollectionProducts({ lang: selectedData, id: 4 }).then((res) => {
            SetBest(res[0].data);
        });
    }, [selectedData]);

    useEffect(() => {
        fetch(`${url}/api/banners`)
            .then((response) => response.json())
            .then((res) => {
                if (res) GetBanner(res);
            });
    }, []);

    // useEffect(() => {

    //     shopApi.getFeaturedProducts({ lang: selectedData }).then(res => Setfeatured(res))

    // }, [selectedData]);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`${theme.name}`}</title>
            </Helmet>

            {useMemo(
                () => (
                    <BlockSlideShow />
                ),
                []
            )}

            {/* {useMemo(() => <BlockFeatures />, [])} */}
            <Partner />

            {useMemo(
                () => (
                    <BlockProducts customer={customer} title={bestSeller} layout="large-first" products={note} />
                ),
                [note, customer.token]
            )}

            <div>
                <BlockBanner url={banner && banner.length && banner[0] ? banner[0].path : ""} />
            </div>
            {useMemo(
                () => (
                    <BlockProducts customer={customer} title={specialoffer} layout="large-first" products={limitProd} />
                ),
                [limitProd, customer.token]
            )}

            <div>
                <BlockBanner url={banner && banner.length && banner[1] ? banner[1].path : ""} />
            </div>

            <BlockProducts
                customer={customer}
                title={newproduct}
                layout="large-first"
                style="w-100"
                products={bestsellers}
            />

            {/* <BlockPosts /> */}
        </React.Fragment>
    );
}

export default HomePageOne;
