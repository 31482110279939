// react
import React, {useEffect, useReducer, useState} from "react";
import {useSelector} from "react-redux";

import PropTypes from "prop-types";
import ProductCardShopSearch from "../shared/ProductCardShopSearch";
import shopApi from "../../api/shop";
import {FormattedMessage} from "react-intl";
import BlockLoader from "../blocks/BlockLoader";

function ShopPageSearch(props) {
    // let productData = JSON.parse(localStorage.getItem("searchProductsFm"));

    const customer = useSelector((state) => state.customer);
    const selectedLocale = useSelector((state) => state.locale);
    const [productData, setProductData] = useState([])
    const [loader, setLoader] = useState(false)
    const [searchedWord, setSearchedWord] = useState("")
    let smalls;

    useEffect(() => {
        const options = {limit: 10, lang: selectedLocale};
        const query = decodeURI(props.location.search.split("search=")[1])
        setSearchedWord(query)
        setLoader(true)
        shopApi.getSeachProducts(query, options)
            .then((products) => {
                setProductData(products)
                setLoader(false)
            })
    }, [props.location.search]);

    if (productData.length > 0) {
        const productsList = productData.map((product, index) => {
            return (
                <div key={index} className="product_fms">
                    <div className="block-products__list-item">
                        <ProductCardShopSearch product={product} customer={customer}/>
                    </div>
                </div>
            );
        });

        smalls = (
            <div className={props.style ? `block-products__list ${props.style}` : `block-products__list`}>
                {productsList}
            </div>
        );
    } else {
        smalls = <div style={{textAlign: "center"}}>
            <FormattedMessage id="SorryNothingFoundFor" defaultMessage="Sorry, nothing found for"/>
        </div>


    }

    return loader
        ? <BlockLoader/>
        : <div className="search-prod_fm">
            <div className="searched_word">
                <h1>
                    <FormattedMessage id="search_result" defaultMessage="Search results"/>
                    <span>{searchedWord}</span>
                </h1>
            </div>
            <div className="container_fm">
                <div className="search-product_fm">{smalls}</div>
            </div>
        </div>
}

export default ShopPageSearch;

ShopPageSearch.propTypes = {
    products: PropTypes.array,
};
