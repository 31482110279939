// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import DropdownLanguage from './DropdownLanguage';

function TopbarT() {

    return (
        <div className="site-header__topbar topbarT d-lg-flex d-none">
            <div className="topbar__container container_fm">
                <div className="topbarT-text text-center">
                    <FormattedMessage id="topRow" defaultMessage="LikeMobile.am" />
                </div>
            </div>
        </div>
    );
}

export default TopbarT;
