// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// application
import AppLink from "../shared/AppLink";
import Collapse from "../shared/Collapse";
import { ArrowRoundedDown12x7Svg } from "../../svg";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

function MobileLinks(props) {
    const { links, level, onItemClick } = props;
    const dispatch = useDispatch();
    const selectedData = useSelector((state) => state.locale);
    const handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };
    const linksList = links.map((link, index) => {
        let item;
        item = (
            <Collapse
                toggleClass="mobile-links__item--open"
                render={({ toggle, setItemRef, setContentRef }) => {
                    let arrow;
                    let subLinks;
                    let linkOrButton;

                    link.type = "link";
                    if (props.type == "pages") {
                        linkOrButton = (
                            <>
                                <AppLink
                                    to={`/menu-page/${link.page_id}`}
                                    className="mobile-links__item-link"
                                    onClick={() => handleItemClick(link)}
                                >
                                    {link.label || <FormattedMessage id={link.slug} defaultMessage={link.name} />}
                                </AppLink>
                            </>
                        );
                    } else {
                        if (link.childs && link.childs.length > 0) {
                            arrow = (
                                <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                    <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                                </button>
                            );

                            subLinks = (
                                <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                    <MobileLinks links={link.childs} level={level + 1} onItemClick={onItemClick} />
                                </div>
                            );
                        } else if (link.children && link.children.length > 0) {
                            arrow = (
                                <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                    <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                                </button>
                            );

                            subLinks = (
                                <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                    <MobileLinks links={link.children} level={level + 1} onItemClick={onItemClick} />
                                </div>
                            );
                        }

                        if (link.data) {
                            linkOrButton = (
                                <AppLink className="mobile-links__item-link">
                                    <div
                                        onClick={(item) => {
                                            handleItemClick(link);
                                            dispatch({ type: "LOCALE_CHANGE", locale: link.data.locale });
                                        }}
                                    >
                                        {link.label || <FormattedMessage id={link.slug} defaultMessage={link.name} />}
                                    </div>
                                </AppLink>
                            );
                        } else {
                            linkOrButton = (
                                <>
                                    <AppLink
                                        // to={`/shop/catalog?category_id=${link.id}`}
                                        to={`/shop/catalog/${link.slug}`}
                                        className="mobile-links__item-link"
                                        onClick={() => handleItemClick(link)}
                                    >
                                        {link.label || <FormattedMessage id={link.slug} defaultMessage={link.name} />}
                                    </AppLink>
                                </>
                            );
                        }
                    }

                    return (
                        <div className="mobile-links__item" ref={setItemRef}>
                            <div className="mobile-links__item-title">
                                {linkOrButton}
                                {arrow}
                            </div>
                            {subLinks}
                        </div>
                    );
                }}
            />
        );

        return <li key={index}>{item}</li>;
    });

    return <ul className={`mobile-links mobile-links--level--${level}`}>{linksList}</ul>;
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};

export default MobileLinks;
