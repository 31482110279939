// react
import React, { useEffect,useState }  from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fb,Inst } from '../../svg';


// data stubs
// import theme from '../../data/theme';
import { url } from '../../helper';

function SocialLinks(props) {
    const { shape, className } = props;
    const [social, getSocial] = useState();
    let items;
    useEffect(() => {
        fetch(`${url}/api/social`)
                .then(res => res.json())
                .then(data => {
                    if(data && data)
                        getSocial(data)
                })
            },[]);
          
    const classes = classNames(className, 'social-links', {
        'social-links--shape--circle': shape === 'circle',
        'social-links--shape--rounded': shape === 'rounded',
    });

    // [
    //     { type: 'facebook', url: theme.fb, icon: <Fb /> },
       
    //     { type: 'instagram', url: theme.instagram, icon: <Inst /> },
    //     { type: 'youtube', url: 'theme.instagram', icon: <You /> },
    // ].map((item) =>

    // const fb = social && social.facebook? (
    //     <li className="social-links__item">
    //         <a
    //             href={social.facebook}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //         >
    //             <Fb />
    //         </a>
    //     </li>
    // ): '';

    // const inst = social && social.instagram? (
        // <li className="social-links__item">
        //     <a
        //         href={social.instagram}
        //         target="_blank"
        //         rel="noopener noreferrer"
        //     >
        //         <Inst />
        //     </a>
        // </li>
    // ):'';



    // const you = social && social.youtube?(
    //     <li className="social-links__item">
    //         <a
    //             href={social.youtube}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //         >
    //             <You />
    //         </a>
    //     </li>
    // ):'';
    if(social){
    items = social.map((item, index) => {
        return(
            <div className="social-items-fms">
           <div key={index} className="social-links__item">
               <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                >
               <img src={`${url}/storage/icons/${item.icon}`}/>
               </a>
           </div>
        </div>
        )
    });
    }



    return (
       
            <div className='social-links-items_fms'>{items}</div>
    
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(['circle', 'rounded']),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
