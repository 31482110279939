// react
import React, { Component } from "react";

// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, Redirect } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import { cartRemoveAllItems } from "../../store/cart";

// application
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";
import ReactPixel from "react-facebook-pixel";
// data stubs
import payments from "../../data/shopPayments";
import theme from "../../data/theme";

import { FormattedMessage, injectIntl } from "react-intl";

import { url } from "../../helper";
import ThankPage from "../site/ThankPage";
import AsyncAction from "../shared/AsyncAction";

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
};
const phonenumber = RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/);

//const url='https://megalight-api.fidem.am';

class ShopPageCheckout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addressesData: null,
            addressesChecked: null,
            loading: false,
            customer: this.props.customer,
            loading: false,
            checkbox: false,
            cart_token: this.props.cart_token,
            token: this.props.token,
            locale: this.props.locale,
            payment: "cashondelivery",
            payments: null,
            ShippingAddress: false,
            notes: "",
            fullName: "",
            lname: "",
            shipingLname: "",
            country: "AM",
            city: "",
            shipingCity: "",
            name: "",
            street: "",
            shipingStreet: "",
            shipingPhone: "",
            phone: "",
            appartment: "",
            email: "",
            shipingEmail: "",
            checkboxCheck: false,

            recAccount: "",
            billNo: "",
            amount: "",

            errors: {
                fullName: "",
                city: "",
                name: "",
                checkboxCheck: false,
                street: "",
                shipingEmail: "",
                shipingCity: "",
                shipingLname: "",
                shipingStreet: "",
                appartment: "",
                shipingPhone: "",
                phone: "",
                email: "",
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            let save_shipping = {
                method: "GET",
            };
            fetch(url + `/api/payments?locale=${nextProps.locale}`, save_shipping)
                .then((responce) => responce.json())
                .then((res) => {
                    this.setState({ payments: res });
                })
                .catch((err) => console.error(err));
        }
    }

    componentDidMount() {
        ReactPixel.trackCustom("Checkout Page");
        const costumerData = this.props.customer.customerName;
        if (this.state.customer.token)
            fetch(`${url}/api/addresses?token=${this.state.customer.token}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.data.length) {
                        this.setState({ addressesData: res.data, addressesChecked: res.data[0].id });
                        this.setState({
                            fullName: costumerData.first_name,
                            lname: costumerData.last_name,
                            city: res.data[0].city,
                            street: res.data[0].address1,
                            email: costumerData.email,
                            phone: costumerData.phone,
                        });
                    }
                })
                .catch((err) => console.error(err));

        let save_shipping = {
            method: "GET",
        };
        fetch(url + `/api/payments?locale=${this.state.locale}`, save_shipping)
            .then((responce) => responce.json())
            .then((res) => {
                this.setState({ payments: res });
            })
            .catch((err) => console.error(err));
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    handleAddressChange = (event) => {
        let address = this.state.addressesData;
        const costumerData = this.props.customer.customerName;
        if (event.target.checked) {
            this.setState({ addressesChecked: event.target.value });
            address = address.filter((e) => e.id == event.target.value);
            this.setState({
                fullName: costumerData.first_name,
                lname: costumerData.last_name,
                city: address[0].city,
                street: address[0].address1,
                email: costumerData.email,
                phone: costumerData.phone,
            });
        }
    };

    handleAddressChangeNew = (event) => {
        this.setState({ addressesChecked: event.target.value, street: "", city: "" });
    };

    renderTotals() {
        const { cart } = this.props;
        if (!cart.extraLines || cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => {
            if (extraLine.type === "shipping") {
                return (
                    <tr key={index}>
                        {/* <th><FormattedMessage id="check.shipping" defaultMessage="Shipping" /></th>
                    <td><Currency value={+extraLine.price} /></td> */}
                    </tr>
                );
            }
        });

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>
                            <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
                        </th>
                        <td>
                            <Currency value={+cart.total} />
                        </td>
                    </tr>
                    {/* {extraLines} */}
                </tbody>
            </React.Fragment>
        );
    }
    renderCart() {
        const { cart } = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name}`}</td>
                <td>
                    {item.quantity} × <Currency value={+item.total} />
                </td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage id="global.product" defaultMessage="Product" />
                        </th>
                        <th>
                            <FormattedMessage id="total" defaultMessage="Total" />
                        </th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{items}</tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>
                            <FormattedMessage id="total" defaultMessage="Total" />
                        </th>
                        <td>
                            <Currency value={+cart.total} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;
        if (this.state.payments) {
            const payments = this.state.payments.map((payment) => {
                const renderPayment = ({ setItemRef, setContentRef }) => (
                    <li className="payment-methods__item" ref={setItemRef}>
                        <label className="payment-methods__item-header">
                            <span className="payment-methods__item-radio input-radio">
                                <span className="input-radio__body">
                                    <input
                                        type="radio"
                                        className="input-radio__input"
                                        name="checkout_payment_method"
                                        value={payment.method}
                                        checked={currentPayment === payment.method}
                                        onChange={this.handlePaymentChange}
                                    />

                                    <span className="input-radio__circle" />
                                </span>
                            </span>
                            <span className="payment-methods__item-title">
                                {payment.title}
                                <br />
                                {currentPayment == "moneytransfer" && payment.method == "moneytransfer"
                                    ? payment.description
                                    : ""}
                            </span>
                        </label>
                        <div className="payment-methods__item-container" ref={setContentRef}>
                            <img
                                style={{
                                    margin: "auto",
                                    display: "block",
                                    width: "200px",
                                }}
                                src={payment.img}
                                alt=""
                            />
                        </div>
                    </li>
                );

                return (
                    <Collapse
                        key={payment.method}
                        open={currentPayment === payment.method}
                        toggleClass="payment-methods__item--active"
                        render={renderPayment}
                    />
                );
            });

            return (
                <div className="payment-methods">
                    <ul className="payment-methods__list">{payments}</ul>
                </div>
            );
        }
    }

    openAddress() {
        this.setState({ ShippingAddress: !this.state.ShippingAddress });
    }

    handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;
        console.log(name, value);
        let errors = this.state.errors;
        this.setState({ [name]: value });
    };

    onclick = (event) => {
        this.setState({ checkbox: !this.state.checkbox, checkboxCheck: !this.state.checkboxCheck });
    };

    chacking = () => {
        const errors = this.state.errors;
        errors.checkboxCheck = this.state.checkboxCheck === false ? "This field is required" : "";
        errors.fullName = this.state.fullName === "" ? "This field is required" : "";

        errors.city = this.state.city === "" ? "This field is required" : "";

        errors.email = validEmailRegex.test(this.state.email) ? "" : "This field is required";

        errors.street = this.state.street === "" ? "This field is required" : "";

        errors.phone = phonenumber.test(this.state.phone) ? "" : "This field is required";

        if (this.state.ShippingAddress) {
            errors.name = this.state.name < 5 ? "This field is required" : "";
            errors.shipingStreet = this.state.shipingStreet.length < 5 ? "This field is required" : "";

            errors.shipingPhone = this.state.shipingStreet.length < 5 ? "This field is required" : "";
            errors.shipingLname = this.state.shipingLname.length < 5 ? "This field is required" : "";

            errors.shipingCity = this.state.shipingCity.length < 5 ? "This field is required" : "";

            errors.shipingEmail = this.state.shipingEmail.length < 5 ? "This field is required" : "";
            this.setState({ errors });
        }

        this.setState({ errors });
    };

    destroyCart() {
        this.props.cartRemoveAllItems();
    }

    requestOrder() {
        const { cart, cart_token } = this.props;

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        let shippingDetalies, options, options_payment, save_shipping;
        let street = this.state.street;
        if (typeof street == "string") street = [this.state.street];

        const billing = {
            use_for_shipping: true,
            address1: street,
            email: this.state.email,
            first_name: this.state.fullName,
            last_name: this.state.lname,
            city: this.state.city,
            country: "AM",
            state: "Yerevan",
            postcode: "75017",
            phone: this.state.phone,
            company_name: "FIdem",
        };
        let shipping;

        if (this.state.ShippingAddress) {
            let street1 = this.state.shipingStreet;
            if (typeof street1 == "string") street1 = [street1];
            shipping = {
                email: this.state.shipingEmail,
                last_name: this.state.shipingLname,
                city: this.state.shipingCity,
                first_name: this.state.name,
                country: "AM",
                state: "Yerevan",
                postcode: "75017",
                // company_name: '',
                address1: street1,
                phone: this.state.shipingPhone,
            };
        } else {
            shipping = {
                address1: [""],
            };
        }

        if (this.state.customer.token) {
            options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    billing: billing,
                    shipping: shipping,
                    // api_token: cart.cart_token,
                    // token: this.state.customer.token,
                }),
            };
            options_payment = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    payment: { method: this.state.payment },
                    api_token: cart_token,
                    token: this.state.customer.token,
                }),
            };
        } else {
            options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    billing: billing,
                    shipping: shipping,
                    api_token: cart_token,
                }),
            };
            options_payment = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    payment: { method: this.state.payment },
                    api_token: cart_token,
                }),
            };
        }

        save_shipping = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                shipping_method: "free_free",
                api_token: cart_token,
            }),
        };

        fetch(
            url +
                `/api/checkout/save-address?api_token=${cart_token}${
                    this.state.customer.token ? `&token=${this.state.customer.token}` : ""
                }`,
            options
        )
            .then((response) => {
                if (response.ok) {
                    fetch(
                        url +
                            `/api/checkout/save-shipping${
                                this.state.customer.token ? `?token=${this.state.customer.token}` : ""
                            }`,
                        save_shipping
                    )
                        .then((res) => {
                            if (res.ok) {
                                fetch(url + "/api/checkout/save-payment", options_payment)
                                    .then((rsponce) => {
                                        if (rsponce.ok) {
                                            let body;
                                            if (this.state.customer.token) {
                                                body = {
                                                    api_token: cart_token,
                                                    token: this.state.customer.token,
                                                    description: this.state.notes,
                                                };
                                            } else {
                                                body = {
                                                    api_token: cart_token,
                                                    description: this.state.notes,
                                                };
                                            }

                                            fetch(url + "/api/checkout/save-order", {
                                                method: "POST",
                                                headers: headers,
                                                body: JSON.stringify(body),
                                            })
                                                .then((res) => res.json())
                                                .then((res) => {
                                                    if (res.success) {
                                                        // const dispatch = useDispatch();
                                                        // dispatch(this.props.removeAllItems());
                                                        // removeAllItems
                                                        // console.log();
                                                        // dispatch({ type: "WISH_LIST", payload: arr });

                                                        if (typeof res.redirect_url == "undefined") {
                                                            this.destroyCart();
                                                            window.location = "/thanks?orderID=" + res.order.id;
                                                        }
                                                        if (
                                                            res.redirect_url.backURL !=
                                                            "https://money.idram.am/payment.aspx"
                                                        ) {
                                                            if (res.redirect_url) {
                                                                this.destroyCart();
                                                                window.location = res.redirect_url;
                                                            } else {
                                                                console.log("sj");
                                                                this.destroyCart();
                                                                window.location = "/thanks?orderID=" + res.order.id;
                                                            }
                                                        } else {
                                                            this.setState({
                                                                recAccount: res.redirect_url.EDP_REC_ACCOUNT,
                                                            });
                                                            this.setState({ billNo: res.redirect_url.EDP_BILL_NO });
                                                            this.setState({ amount: res.redirect_url.Amount });
                                                            let url = `https://banking.idram.am/Payment/GetPayment?EDP_REC_ACCOUNT=${
                                                                res.redirect_url.EDP_REC_ACCOUNT
                                                            }&EDP_AMOUNT=${+res.redirect_url.Amountl}&EDP_BILL_NO=${
                                                                res.redirect_url.EDP_BILL_NO
                                                            }`;
                                                            console.log(url, "ji");
                                                            document.getElementById("idramSubmit").click();
                                                            window.location = `https://banking.idram.am/Payment/GetPayment?EDP_REC_ACCOUNT=${
                                                                res.redirect_url.EDP_REC_ACCOUNT
                                                            }&EDP_AMOUNT=${+res.redirect_url.Amountl}&EDP_BILL_NO=${
                                                                res.redirect_url.EDP_BILL_NO
                                                            }`;
                                                            console.log(
                                                                this.setState({ amount: res.redirect_url.Amount })
                                                            );
                                                        }
                                                    }
                                                })
                                                .catch((err) => console.log(err, "err"));
                                        }
                                    })
                                    .then((res) => {
                                        // redirect to thankyou page

                                        console.log(res, "lplplpl");
                                    });
                            }
                        })
                        .catch((err) => console.error(err));
                }
            })

            .catch((err) => console.log(err, "err"));

        //   return <Redirect to="/thanks" />;
    }

    clicking = () => {
        document.getElementById("idramSubmit").click();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        this.chacking();
        if (validateForm(this.state.errors)) {
            this.requestOrder();
        } else {
            this.setState({ loading: false });
            console.log("Invalid Form", this.state.errors);
        }
    };

    render() {
        const { cart } = this.props;
        const { errors, addressesData, addressesChecked } = this.state;
        const { locale } = this.props;
        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }

        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "/shop/cart" },
            { title: "Checkout", url: "" },
        ];

        if (this.state.addressesChecked == null) {
            this.setState({ addressesChecked: "newAddress" });
        }

        return (
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header__container container">
                        <div className="page-header__title">
                            <h1>
                                <FormattedMessage id="checkout" defaultMessage="Checkout" />
                            </h1>
                        </div>
                    </div>
                </div>
                {console.log(this.state.recAccount, "recAccount")}

                <form className="idramform" action="https://banking.idram.am/Payment/GetPayment" method="POST">
                    <input type="hidden" name="EDP_LANGUAGE" value="EN" />
                    <input type="hidden" name="EDP_REC_ACCOUNT" value={this.state.recAccount} />
                    {/* <input type="hidden" name="EDP_DESCRIPTION" value={items} /> */}
                    <input type="hidden" name="EDP_AMOUNT" value={this.state.amount} />
                    <input type="hidden" name="EDP_BILL_NO" value={this.state.billNo} />
                    <input id="idramSubmit" type="submit" value="submit" />
                </form>
                {this.state.recAccount ? this.clicking() : ""}

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                    <form>
                                        <div className="card-body">
                                            <h3 className="card-title">
                                                <FormattedMessage
                                                    id="checkout.billing"
                                                    defaultMessage="Billing details"
                                                />
                                            </h3>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <FormattedMessage id="Checkout.name" defaultMessage="Անուն">
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.fullName}
                                                                type="text"
                                                                name="fullName"
                                                                className="form-control"
                                                                id="checkout-first-name"
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.fullName.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.fullName}
                                                            />
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <FormattedMessage id="Checkout.lname" defaultMessage=" Ազգանուն">
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.lname}
                                                                type="text"
                                                                name="lname"
                                                                className="form-control"
                                                                id="checkout-first-name"
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.fullName.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.fullName}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                {/* <div className="form-group col-md-6">

                                                    <FormattedMessage id="global.country" defaultMessage="Country">

                                                        {(placeholder) => <input
                                                            onChange={this.handleChange}
                                                            value={this.state.country}
                                                            type="text"
                                                            name='country'
                                                            className="form-control"
                                                            id="checkout-country"
                                                            placeholder={placeholder}
                                                            disabled="disabled"
                                                        />

                                                        }
                                                    </FormattedMessage>
                                                </div> */}
                                            </div>

                                            <div className="form-row form-row_column-row">
                                                <div className="form-group col-md-6">
                                                    <FormattedMessage id="email" defaultMessage="Email">
                                                        {(placeholder) => (
                                                            <input
                                                                value={this.state.email}
                                                                onChange={this.handleChange}
                                                                type="email"
                                                                name="email"
                                                                className="form-control"
                                                                id="checkout-email"
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.email.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.email}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <FormattedMessage id="checkout.phone" defaultMessage="Phone number">
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.phone}
                                                                name="phone"
                                                                type="text"
                                                                className="form-control"
                                                                id="checkout-phone"
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.phone.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.phone}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="card-divider" />
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            <FormattedMessage id="shippingAddress" defaultMessage="Առաքման հասցե" />
                                        </h3>
                                        <div className="payment-methods">
                                            <ul className="payment-methods__list">
                                                {addressesData
                                                    ? addressesData.map((address) => (
                                                          <li className="payment-methods__item">
                                                              <label className="payment-methods__item-header">
                                                                  <span className="payment-methods__item-radio input-radio">
                                                                      <span className="input-radio__body">
                                                                          <input
                                                                              type="radio"
                                                                              className="input-radio__input"
                                                                              name="checkout_address_method"
                                                                              value={address.id}
                                                                              checked={
                                                                                  addressesChecked == address.id
                                                                                      ? "checked"
                                                                                      : ""
                                                                              }
                                                                              onChange={this.handleAddressChange}
                                                                          />
                                                                          <span className="input-radio__circle" />
                                                                      </span>
                                                                  </span>
                                                                  <span className="payment-methods__item-title">
                                                                      {address.address1[0]}
                                                                  </span>
                                                              </label>
                                                          </li>
                                                      ))
                                                    : ""}
                                                {addressesData?.length > 0 && (
                                                    <li className="payment-methods__item">
                                                        <label className="payment-methods__item-header">
                                                            <span className="payment-methods__item-radio input-radio">
                                                                <span className="input-radio__body">
                                                                    <input
                                                                        type="radio"
                                                                        className="input-radio__input"
                                                                        name="checkout_address_method"
                                                                        value="newAddress"
                                                                        checked={
                                                                            this.state.addressesChecked == "newAddress"
                                                                                ? "checked"
                                                                                : ""
                                                                        }
                                                                        onChange={this.handleAddressChangeNew}
                                                                    />
                                                                    <span className="input-radio__circle" />
                                                                </span>
                                                            </span>
                                                            <span className="payment-methods__item-title">
                                                                <FormattedMessage
                                                                    id="ship.address"
                                                                    defaultMessage="Ship to a different address?"
                                                                />
                                                            </span>
                                                        </label>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        {/* <div className="form-group">
                                            <div className="form-check" >
                                                <span onClick={this.openAddress.bind(this)} className="form-check-input input-check">
                                                    <span className="input-check__body">

                                                        <input

                                                            className="input-check__input"
                                                            type="checkbox"
                                                            id="checkout-different-address" />

                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label  >
                                                    <FormattedMessage id="chekout.shippingDifferent" defaultMessage="Ship to a different address?" />
                                                </label>
                                            </div>
                                        </div> */}

                                        {this.state.addressesChecked == "newAddress" ? (
                                            <>
                                                <div className="form-group">
                                                    <FormattedMessage id="city" defaultMessage="Քաղաք">
                                                        {(placeholder) => (
                                                            <input
                                                                type="text"
                                                                name="city"
                                                                onChange={this.handleChange}
                                                                value={this.state.city}
                                                                className="form-control"
                                                                id="checkout-city"
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.city.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.city}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <FormattedMessage id="address" defaultMessage="Address">
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.street}
                                                                name="street"
                                                                type="text"
                                                                className="form-control"
                                                                id="checkout-street"
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </FormattedMessage>

                                                    {errors.street.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.street}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <div className="form-group">
                                            <FormattedMessage id="order.notes" defaultMessage="Order notes" />
                                            <textarea
                                                onChange={this.handleChange}
                                                name="notes"
                                                id="checkout-comment"
                                                className="form-control"
                                                rows="4"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            {" "}
                                            <FormattedMessage id="order" defaultMessage="Your Order" />
                                        </h3>

                                        {this.renderCart()}

                                        {this.renderPaymentsList()}

                                        <div className="checkout__agree form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            onClick={this.onclick}
                                                            // onChange={this.handleChange}
                                                            className="input-check__input"
                                                            type="checkbox"
                                                            id="checkout-check"
                                                            name="checkboxCheck"
                                                            // checked={this.state.checkboxCheck == 'on'}
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                    {errors.checkboxCheck.length > 0 && (
                                                        <span className="alert-danger">
                                                            {" "}
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.checkboxCheck}
                                                            />
                                                        </span>
                                                    )}
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-terms">
                                                    <Link to="/page/4" target="_blank">
                                                        {" "}
                                                        <FormattedMessage
                                                            id="order.term"
                                                            defaultMessage="I agree with Privacy Policy"
                                                        />
                                                    </Link>
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                            </div>
                                        </div>

                                        {console.log(this.state.loading)}
                                        {/* <AsyncAction
                                                       
                                                        render={({ run, loading }) => ( */}
                                        <button
                                            onClick={this.handleSubmit}
                                            type="submit"
                                            className={
                                                !this.state.loading
                                                    ? "check_butt_fms btn-primary"
                                                    : "btn-loading check_butt_fms btn-primary"
                                            }
                                        >
                                            <FormattedMessage id="pay" defaultMessage="Վճարել" />
                                        </button>
                                        {/* )}
                                                    /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    cart_token: state.cartToken.cartToken,
    locale: state.locale,
    token: state.cart.cart_token,
    customer: state.customer,
});

const mapDispatchToProps = {
    cartRemoveAllItems,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout));
