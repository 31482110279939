// react
import React, {Component} from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {url} from "../../helper";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import {cartAddItem} from "../../store/cart";
import {AddImages} from "../../store/images";
import {AddCartToken} from "../../store/token";
import {compareAddItem} from "../../store/compare";
import {Bedge1, Bedge3, Check12x9Svg, FailSvg, Fb, FM16Svg, Inst, Wishlist16Svg} from "../../svg";
import {wishlistAddItem} from "../../store/wishlist";
import {FormattedMessage} from "react-intl";
import {Helmet} from "react-helmet-async";
import {toast} from "react-toastify";
import FilterColor from "../filters/FilterColor";
import {colorType} from "../../services/color";
import {FacebookShareButton, FacebookIcon} from "react-share";

class Product extends Component {
    checkAddProd = false;
    cartProduct = null;
    refs = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            customer: this.props.customer,
            quantity: 1,
            locale: this.props.locale,
            token: this.props.token,
            compare: this.props.compare,
            product: null,
            productConst: null,
            imagesData: null,
            simpleProduct: null,
            RAM: null,
            Memory: null,
            color: null,
            isAllChecked: true,
        };
    }


    componentDidMount() {
        if (this.props.product.data.parent_id) {
            fetch(url + `/api/product-configurable-config/${this.props.product.data.parent_id}?locale=${this.props.locale}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.data) {
                        this.props.product.data = Object.assign(this.props.product.data, {
                            attribute: res.data.attributes,
                            index: res.data.index,
                            regular_price: res.data.regular_price,
                            variant_images: res.data.variant_images,
                            variant_prices: res.data.variant_prices,
                        });
                        let filterVariant = this.props.product.data.variants.filter(
                            (e) => e.id === this.props.product.data.id
                        );

                        if (filterVariant.length > 0) {
                            this.props.AddImages(filterVariant[0].images);
                        }

                        this.selectDef(this.props.product.data, filterVariant[0]);

                        this.setState({
                            product: this.props.product.data,
                            simpleProduct: filterVariant[0],
                            imagesData: this.props.product.data.images,
                        });
                    }
                })
                .catch((err) => console.error(err));
        } else {
            this.checkAddProd = true;
            this.setState({
                product: this.props.product.data,
                simpleProduct: this.props.product.data,
                imagesData: this.props.product.data.images,
            });
            this.props.AddImages(this.props.product.data.images);
        }
        if (this.props.cartToken.cartToken === "") {
            fetch(url + "/api/checkout/cart/token")
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.api_token) {
                        this.props.AddCartToken(res.api_token);
                    }
                })
                .catch((err) => console.error(err));
        }
    }

    selectDef = (prod, simple) => {
        prod.attribute.forEach((element) => {
            this.setState({
                [element.code]: {
                    id: prod.index[simple.id][element.id],
                    code: element.code
                }
            });
        });
    };

    items = [
        {
            type: "facebook",
            icon: (
                <FacebookShareButton url={window.location.href}>
                    <Fb/>
                </FacebookShareButton>
            ),
        },

        {type: "instagram", icon: <Inst/>},
        // { type: 'youtube', icon: <You /> },
    ].map((item) => (
        <div className="mr-3">
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.icon}
            </a>
        </div>
    ));

    createMarkup(item) {
        return {__html: item};
    }

    cartValidFunc = async () => {
        let attrCheck = {};
        let attr = {};
        this.state.product.attribute.forEach((e) => {
            attr = Object.assign(attr, {[e.code]: document.getElementsByName(e.code)});
            attrCheck = Object.assign(attrCheck, {[e.code]: [false, e.id]});
        });

        for (let attrItem in attr) {
            for (let i of attr[attrItem]) {
                if (i.checked == true) {
                    attrCheck[attrItem][0] = true;
                }
            }
        }

        for (let attrItem in attrCheck) {
            let item = document.getElementById(`attr_${attrCheck[attrItem][1]}`);

            if (attrCheck[attrItem][0] == false) {
                item.style.display = "block";
            } else {
                item.style.display = "none";
            }
        }
        await "";
    };

    handleChangeQuantity = (quantity) => {
        this.setState({quantity});
    };

    handleChangeStyle = (event, code, id) => {
        let itemList = document.getElementsByClassName(code + "_fm");
        let itemRadio = document.getElementById(code + id);
        for (let e of itemList) {
            e.style.borderColor = "#929292";
        }

        let item = document.getElementById(event.target.value + code);

        item.style.borderColor = "#FFD34E";
        event.target.checked = true;
        item.style.opacity = "1"
    };

    sortObj = (obj) => {
        return Object.keys(obj)
            .sort()
            .reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
    };

    changeImage = (product) => {
        let data;
        for (let i in this.state.product.variant_images) {
            if (i == product[0]) {
                data = this.state.product.variant_images[i];
                break;
            }
        }
        this.props.AddImages(data);
    };

    handleChange = (product, type) => {
        let prod = this.state.product;
        let valueId = {};
        let attrCheck = {};
        let attr = {};
        let check = true;
        let prodId = null;

        this.props.product.data.attribute.forEach((e) => {
            attr = Object.assign(attr, {[e.code]: [...document.getElementsByName(e.code)]});
            attrCheck = Object.assign(attrCheck, {[e.code]: [false, e.id]});
        });

        for (let attrItem in attr) {
            for (let i of attr[attrItem]) {
                if (i.checked) {
                    valueId = Object.assign(valueId, {[attrCheck[attrItem][1]]: Number(i.value)});
                    attrCheck[attrItem][0] = true;
                }
            }
        }

        for (let attrItem in attrCheck) {
            if (attrCheck[attrItem][0] === false) {
                check = false;
                break;
            }
        }

        valueId = JSON.stringify(this.sortObj(valueId)).slice(1, -1)


        for (let item in prod.index) {
            let elem = JSON.stringify(this.sortObj(prod.index[item]));

            if (elem.includes(valueId)) {
                prodId = item;
            }
        }

        // if (check === false) {
        //     for (let attrItem in attrCheck) {
        //         let item = document.getElementById(`attr_${attrCheck[attrItem][1]}`);
        //         // item.style.display = 'none'
        //     }
        //     this.checkAddProd = true;
        // }

        let checkedArray = []
        this.state.product.attribute.forEach((element, i) => {
            if (element.code !== type && element.options.length !== 1) {
                element.options.forEach((option, index) => {
                    let item = document.getElementById(option.id + element.code);
                    let fill = []
                    for (let elem = 0; elem < product.length; elem++) {
                        fill.push(...option.products.filter((es) => es === product[elem]))
                    }
                    if (fill.length === 0) {
                        item.style.opacity = "0.4";
                        item.style.borderColor = "#929292";
                        let radio = document.getElementById(element.code + option.id);
                        radio.checked = false
                    } else {
                        item.style.opacity = "1";
                        // break;
                    }
                });
            }

            let elementsArray = []
            for (let option of element.options) {
                elementsArray.push(document.getElementById(element.code + option.id).checked)
            }
            checkedArray.push(elementsArray.some(el => el === true))
        });

        this.setState({isAllChecked: !checkedArray.some(el => el === false)})

        if (prodId) {
            let item = prod.variants.filter((e) => e.id === Number(prodId));

            this.cartProduct = item[0];

            this.setState({
                simpleProduct: this.cartProduct,
                imagesData: this.cartProduct.images,
                quantity: Boolean(this.cartProduct.min_qty) ? Number(this.cartProduct.min_qty) : 1
            });
            this.selectDef(this.state.product, this.cartProduct);
        }
    };

    badges = [];
    priceBege = Math.round(
        `${100 - (parseInt(this.props.product.data.old_price) * 100) / parseInt(this.props.product.data.price)}`
    );
    priceSale = ` ${this.priceBege} %`;

    render() {
        const {
            // product,
            layout,
            wishlistAddItem,
            compareAddItem,
            AddCartToken,
            cartAddItem,
        } = this.props;

        const {quantity, customer, compare, product, imagesData, simpleProduct} = this.state;
        const wishListStorage = JSON.parse(localStorage.getItem("state"));
        if (product) {
            if (wishListStorage.wishlist.length) {
                if (wishListStorage.wishlist.filter((e) => e.id === product.id).length)
                    Object.assign(product, {wishlist: true});
                else {
                    Object.assign(product, {wishlist: false});
                }
            } else {
                Object.assign(product, {wishlist: false});
            }
        }
        let prices;
        let messageLogin = (
            <span className="d-flex faild-toast-fms">
                <FailSvg/>
                <FormattedMessage
                    id="please.login.toast "
                    defaultMessage="`Please login or register in order to add to favorites.`"
                />
            </span>
        );

        if (this.props.product.data.old_price) {
            this.badges.push(
                <div className="badges-fms pointed io">
                    <span>{this.priceSale}</span>
                </div>
            );
        } else if (this.props.product.data.old_price && this.props.product.data.new === 1) {
            this.badges.push(
                <div className="badges-fms pointed io">
                    <span>{this.priceSale}</span>
                    <Bedge1/>
                </div>
            );
        }
        if (this.props.product.data.new === 1) {
            this.badges.push(
                <div className="badges-fms io">
                    <Bedge1/>
                </div>
            );
        }
        // if(this.props.product.data.qty === 0) {
        //     this.badges.push(<div className="badges-fms io" ><Bedge3 /></div>)
        // }

        const colorFilter = (attr) => {
            return <div className="color-attr_fm">
                {attr.options.map((e, i) => {
                    const classes = classNames("filter-color__check input-check-color", {
                        "input-check-color--white": colorType(e.swatch_value) === "white",
                        "input-check-color--light": colorType(e.swatch_value) === "light",
                    })

                    return <div
                        key={i}
                        className="filter-color__item"
                        id={e.id + attr.code}
                    >
                        <span
                            className={classes}
                            style={{color: e.swatch_value}}
                        >
                            <label className="input-check-color__body">
                                <input
                                    id={attr.code + e.id}
                                    className="input-check-color__input"
                                    name={attr.code}
                                    type="radio"
                                    value={e.id}
                                    checked={this.state.color.id === e.id}
                                    onChange={() => {
                                        this.changeImage(e.products);
                                        this.handleChange(e.products, attr.code);
                                    }}
                                />
                                <span className="input-check-color__box"/>
                                <Check12x9Svg className="input-check-color__icon"/>
                                <span className="input-check-color__stick"/>
                          </label>
                      </span>
                    </div>
                })}
            </div>
        }

        const otherFilters = (attr) => {
            return <div className="other-attr_fm">
                {attr.options.map((e, i) => {
                    return <label
                        htmlFor={attr.code + e.id}
                        key={i}
                        className={`other-list__item ${attr.code}_fm ${this.state[attr.code].id === e.id && "attr-border-fm"}`}
                        id={e.id + attr.code}
                    >
                        <span className="other-list__input">
                            <span className="other-radio__body">
                                <input
                                    id={attr.code + e.id}
                                    className="other-radio__input"
                                    type="radio"
                                    name={attr.code}
                                    value={e.id}
                                    checked={this.state[attr.code].id === e.id}
                                    onChange={(target) => {
                                        this.setState({[attr.code]: {id: e.id, code: attr.code}})
                                        this.handleChangeStyle(target, attr.code, e.id);
                                        this.handleChange(e.products, attr.code);
                                    }}
                                />
                              </span>
                          </span>
                        <span className="other-list__title">
                            {e.label}
                        </span>
                    </label>
                })}
            </div>
        }

        return (
            <>
                {product ? (
                    <div>
                        <Helmet>
                            <title>{this.state.simpleProduct.name}</title>
                            <meta
                                name="description"
                                content={
                                    this.state.simpleProduct.description
                                        ? this.state.simpleProduct.description.replace(/(<([^>]+)>)/gi, "")
                                        : ""
                                }
                            />
                            <meta
                                name="name"
                                content={this.state.simpleProduct.name ? this.state.simpleProduct.name : ""}
                            />
                            <meta property="og:url" content={window.location}/>
                            <meta property="og:title" content={this.state.simpleProduct.name}/>
                            <meta
                                property="og:image"
                                content={
                                    this.state.simpleProduct.images.lenght > 0 ??
                                    this.state.simpleProduct.images[0].original_image_url
                                }
                            />
                        </Helmet>

                        <div className={`product product--layout--${layout}`}>
                            <div className="product__content">
                                <ProductGallery
                                    ref="productGallery"
                                    layout={layout}
                                    images={imagesData}
                                    badges={this.badges[0]}
                                />

                                <div className="product__info">
                                    <div className="product__wishlist-compare">
                                        <AsyncAction
                                            action={() => {
                                                return wishlistAddItem(
                                                    product,
                                                    customer.token ? customer.token : false,
                                                    product.wishlist
                                                ).then((e) => {
                                                    if (e == false) toast.success(messageLogin);
                                                });
                                            }}
                                            render={({run, loading}) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames("btn btn-sm btn-light btn-svg-icon", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg/>
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <h1
                                        className="product__name"
                                        id="prod_name"
                                        dangerouslySetInnerHTML={this.createMarkup(simpleProduct.name)}
                                    ></h1>
                                    {/* <div dangerouslySetInnerHTML={this.createMarkup(product.data.description)} /> */}
                                </div>

                                <div className="product__sidebar">
                                    <div className="product__prices">
                                        <div>
                                            <div className="cash_title">
                                                <FormattedMessage id="cash" defaultMessage="Կանխիկ գին"/>
                                            </div>
                                            {simpleProduct.old_price ? (
                                                <>
                                                    {" "}
                                                    <span className="product-card__old-price">
                                                        {" "}
                                                        <Currency value={parseInt(simpleProduct.old_price)}/>
                                                    </span>
                                                    <span className="product-card__new-price">
                                                        <Currency value={parseInt(simpleProduct.price)}/>{" "}
                                                    </span>
                                                </>
                                            ) : (
                                                <span>
                                                    <Currency value={parseInt(simpleProduct.price)}/>{" "}
                                                </span>
                                            )}
                                        </div>
                                        {simpleProduct.cost ? (
                                            <div className="ml-4">
                                                <div className="cash_title">
                                                    <Link>
                                                        <FormattedMessage id="credit" defaultMessage="Credit"/>
                                                    </Link>
                                                </div>
                                                {simpleProduct.cost ? (
                                                    <span>
                                                        <Currency value={parseInt(simpleProduct.cost)}/>{" "}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className={"product_attributes"}>
                                        <div className="product__meta">
                                            {
                                                product.attribute && product.attribute.map((attr) => {
                                                    const isColor = attr.code === "color"
                                                    if (attr.code) {
                                                        return <div
                                                            className={`${isColor ? "attr_color_fms" : "attr_fms"}`}>
                                                            <div className="attr_label-fms">
                                                                {attr.label}
                                                            </div>
                                                            {
                                                                attr.options && attr.options.length
                                                                    ? attr.options[0].swatch_value
                                                                        ? colorFilter(attr)
                                                                        : otherFilters(attr)
                                                                    : null
                                                            }
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>

                                    <form className="product__options">
                                        <div className="form-group product__option">
                                            {/* <label htmlFor="product-quantity" className="product__option-label"><FormattedMessage id="qty" defaultMessage="Քանակ" /></label> */}
                                            <div className="product__actions">
                                                <div className="product__actions-item qty_actions-item">
                                                    <InputNumber
                                                        id="product-quantity"
                                                        aria-label="Quantity"
                                                        className="product__quantity"
                                                        size="lg"
                                                        min={1}
                                                        value={quantity}
                                                        onChange={this.handleChangeQuantity}
                                                    />
                                                </div>
                                                <div className="product__actions-item product__actions-item--addtocart">
                                                    <AsyncAction
                                                        action={() =>
                                                            this.state.simpleProduct
                                                                ? cartAddItem(
                                                                    this.state.simpleProduct
                                                                        ? this.state.simpleProduct
                                                                        : product,
                                                                    [],
                                                                    quantity,
                                                                    this.state.token,
                                                                    this.state.customer,
                                                                    this.state.locale
                                                                )
                                                                : this.cartValidFunc()
                                                        }
                                                        render={({run, loading}) => (
                                                            <button
                                                                type="button"
                                                                onClick={run}
                                                                disabled={!quantity || !this.state.isAllChecked}
                                                                className={classNames("add_cart_fms", {
                                                                    "btn-loading": loading,
                                                                })}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="26.65"
                                                                    height="20"
                                                                    viewBox="0 0 26.65 20"
                                                                >
                                                                    <g
                                                                        id="Сгруппировать_117"
                                                                        data-name="Сгруппировать 117"
                                                                        transform="translate(17135.776 18264.001)"
                                                                    >
                                                                        <g
                                                                            id="Сгруппировать_34"
                                                                            data-name="Сгруппировать 34"
                                                                            transform="translate(-17135.777 -18264.002)"
                                                                            opacity="0.95"
                                                                        >
                                                                            <g
                                                                                id="Сгруппировать_23"
                                                                                data-name="Сгруппировать 23"
                                                                                transform="translate(0.001 0.001)"
                                                                            >
                                                                                <path
                                                                                    id="Icon_open-cart"
                                                                                    data-name="Icon open-cart"
                                                                                    d="M-.363,1.931a1.688,1.688,0,0,0,1.933,1.4h5l.3.833L8.237,8.331,9.6,12.5a1.351,1.351,0,0,0,1.133.833H22.4a1.309,1.309,0,0,0,1.133-.833l2.7-8.333c.133-.433-.067-.833-.533-.833H10.9L9.637.931A1.667,1.667,0,0,0,8.17,0H1.5a1.667,1.667,0,0,0-.3,0C1.137-.006-.511,1.011-.363,1.931Z"
                                                                                    transform="translate(0.373 0.009)"
                                                                                    fill="#fff"
                                                                                />
                                                                                <circle
                                                                                    id="Эллипс_8"
                                                                                    data-name="Эллипс 8"
                                                                                    cx="1.852"
                                                                                    cy="1.852"
                                                                                    r="1.852"
                                                                                    transform="translate(11.1 16.296)"
                                                                                    fill="#fff"
                                                                                />
                                                                                <circle
                                                                                    id="Эллипс_9"
                                                                                    data-name="Эллипс 9"
                                                                                    cx="1.852"
                                                                                    cy="1.852"
                                                                                    r="1.852"
                                                                                    transform="translate(19.248 16.296)"
                                                                                    fill="#fff"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>

                                                                <FormattedMessage id="buy" defaultMessage="Գնել"/>
                                                            </button>
                                                        )}
                                                    />
                                                </div>
                                                <div className="product__actions-item-fms">
                                                    <div
                                                        className="product__actions-item product__actions-item--wishlist">
                                                        <AsyncAction
                                                            action={() => {
                                                                return wishlistAddItem(
                                                                    product,
                                                                    customer.token ? customer.token : false,
                                                                    product.wishlist
                                                                ).then((e) => {
                                                                    if (!e) toast.success(messageLogin);
                                                                });
                                                            }}
                                                            render={({run, loading}) => (
                                                                <button
                                                                    type="button"
                                                                    data-toggle="tooltip"
                                                                    title="Wishlist"
                                                                    onClick={run}
                                                                    className={classNames("add-wish-fms", {
                                                                        "btn-loading": loading,
                                                                    })}
                                                                >
                                                                    <Wishlist16Svg/>
                                                                </button>
                                                            )}
                                                        />
                                                    </div>

                                                    <div
                                                        className="product__actions-item product__actions-item--wishlist">
                                                        <AsyncAction
                                                            action={() => compareAddItem(product)}
                                                            render={({run, loading}) => (
                                                                <button
                                                                    type="button"
                                                                    onClick={run}
                                                                    disabled={
                                                                        window.screen["width"] < 991
                                                                            ? compare.length === 2
                                                                            : compare.length === 3
                                                                    }
                                                                    className={classNames(
                                                                        "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                                                        {
                                                                            "btn-loading": loading,
                                                                        }
                                                                    )}
                                                                >
                                                                    <FM16Svg/>
                                                                </button>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product__actions-item product__actions-item--compare mt-3">
                                                <span className="text-success_fms">
                                                    {product.in_stock ? (
                                                        <FormattedMessage id="instock" defaultMessage="Առկա է"/>
                                                    ) : (
                                                        <FormattedMessage id="outOfStock" defaultMessage="Առկա չէ"/>
                                                    )}{" "}
                                                </span>
                                                <span className="sku_fms">
                                                    <FormattedMessage id="Sku" defaultMessage="SKU"/>:
                                                </span>
                                                <span className="sku_fms ml-1">{simpleProduct.sku}</span>
                                            </div>
                                            <div
                                                className="product__actions-item product__actions-item--compare mt-3 d-flex">
                                                <div className="d-flex mr-2">{this.items[0]}</div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="product-null-fms"/>
                )}
            </>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapDispatchToProps = {
    AddCartToken,
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    AddImages,
};

export default connect(
    (state) => ({
        locale: state.locale,
        cartToken: state.cartToken,
        token: state.cartToken,
        customer: state.customer,
        compare: state.compare,
    }),
    mapDispatchToProps
)(Product);
