// react
import React, { Component } from "react";

// application
import departmentsArea from "../../services/departmentsArea";
import { LogoSvg, Menu18x14Svg, Close, Likemobile, MenuSvgMob } from "../../svg";
import { FormattedMessage } from "react-intl";
import { url } from "../../helper";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mobileMenuOpen } from "../../store/mobile-menu";

class Departments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dep: [],
            open: false,
            fixed: false,
            area: null,
            openCatMenu: false,
            openSubCat: false,
        };
    }

    componentDidMount() {
        fetch(`${url}/api/categories`)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    dep: res.categories[0].children,
                });
            });
        document.addEventListener("mousedown", this.handleOutsideClick);

        this.unsubscribeAria = departmentsArea.subscribe((area) => {
            this.setState({
                fixed: !!area,
                area,
            });
        });

        this.setState({
            fixed: !!departmentsArea.area,
            area: departmentsArea.area,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let { fixed, area, open } = this.state;

        if (window.location.pathname === "/") {
            const root = this.rootRef;
            const content = root.querySelector(".departments__links-wrapper");

            root.classList.remove("departments--transition");
            root.classList.add("departments--fixed", "departments--opened");
        } else {
            if (prevState.fixed !== fixed) {
                const root = this.rootRef;
                const content = root.querySelector(".departments__links-wrapper");

                if (fixed) {
                    const areaRect = area.getBoundingClientRect();
                    const areaBottom = areaRect.top + areaRect.height + window.scrollY;

                    root.classList.remove("departments--transition");
                    root.classList.add("departments--fixed", "departments--opened");

                    const height = areaBottom - (content.getBoundingClientRect().top + window.scrollY);

                    content.style.height = `${height}px`;
                    content.getBoundingClientRect(); // force reflow
                } else {
                    root.classList.remove("departments--opened", "departments--fixed");
                    content.style.height = "";
                }
            } else if (!fixed) {
                if (open) {
                    const root = this.rootRef;

                    const content = root.querySelector(".departments__links-wrapper");
                    content.getBoundingClientRect(); // force reflow
                    const startHeight = content.getBoundingClientRect().height;

                    root.classList.add("departments--transition", "departments--opened");

                    const endHeight = content.getBoundingClientRect().height;

                    content.style.height = `${startHeight}px`;
                    content.getBoundingClientRect(); // force reflow
                    content.style.height = `${endHeight}px`;
                } else {
                    // const root = this.rootRef;
                    // const content = root.querySelector('.departments__links-wrapper');
                    // const startHeight = content.getBoundingClientRect().height;
                    // content.style.height = `${startHeight}px`;
                    // root.classList.add('departments--transition');
                    // root.classList.remove('departments--opened');
                    // content.getBoundingClientRect(); // force reflow
                    // content.style.height = '';
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);

        this.unsubscribeAria();
    }

    unsubscribeAria = () => { };

    setWrapperRef = (node) => {
        this.rootRef = node;
    };

    handleOutsideClick = (event) => {
        if (this.rootRef && !this.rootRef.contains(event.target)) {
            this.setState(() => ({
                open: false,
            }));
        }
    };

    handleButtonClick = (e) => {
        if (e.target === e.currentTarget)
            this.setState((state) => ({
                openCatMenu: !state.openCatMenu,
            }));
    };

    handleChildClick = (e) => {
        this.setState((state) => ({
            openCatMenu: !state.openCatMenu,
        }));
    };

    handleSubCatClick = (e) => {
        let item = document.getElementById(`subCat${e}`);
        item.classList.add("db_block");
    };

    leaveSubCatClick = (e) => {
        let item = document.getElementById(`subCat${e}`);
        item.classList.remove("db_block");
    };

    handleTransitionEnd = (event) => {
        if (this.rootRef && event.propertyName === "height") {
            this.rootRef.querySelector(".departments__links-wrapper").style.height = "";
            this.rootRef.classList.remove("departments--transition");
        }
    };

    render() {
        return (
            <div className="departments" ref={this.setWrapperRef}>
                {/* <div className="departments__body">
                    <div className="departments__links-wrapper" onTransitionEnd={this.handleTransitionEnd}>
                        <DepartmentsLinks dep={this.state.dep}  func={this.handleButtonClick} />
                    </div>
                </div> */}
                {this.state.openCatMenu ? (
                    <div className="menu_cat_fms" onClick={this.handleButtonClick}>
                        <div className="close_fm" onClick={this.handleChildClick}>
                            <Close />
                        </div>
                        <div className="cat_list_fms">
                            <div className="site-header__logo" onClick={this.handleChildClick}>
                                <Link to="/">
                                    <LogoSvg />
                                </Link>
                                <Link to="/" className="ml-2">
                                    <Likemobile />
                                </Link>
                            </div>
                            <h3>
                                <FormattedMessage id="Menu.category" defaultMessage="Կատեգորիաներ" />
                            </h3>
                            <div className="cat_list_item_fms">
                                {this.state.dep
                                    ? this.state.dep.map((e) => {
                                        return (
                                            <div
                                                onMouseEnter={() =>
                                                    e.children.length ? this.handleSubCatClick(e.id) : ""
                                                }
                                                onMouseLeave={() =>
                                                    e.children.length ? this.leaveSubCatClick(e.id) : ""
                                                }
                                            >
                                                <img src={url + "/storage/" + e.image_url} alt="" height="26px" />
                                                <Link
                                                    to={`/shop/catalog/${e.slug}`}
                                                    onClick={this.handleChildClick}
                                                    className="shop-castalog_fms"
                                                >
                                                    <span>
                                                        <FormattedMessage id={e.slug} defaultMessage={e.name} />
                                                        {/* {e.name} */}
                                                    </span>
                                                </Link>
                                                {e.children.length ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="5.718"
                                                        height="10"
                                                        viewBox="0 0 5.718 10"
                                                    >
                                                        <path
                                                            id="Icon_ionic-ios-arrow-forward"
                                                            data-name="Icon ionic-ios-arrow-forward"
                                                            d="M15.241,11.194,11.456,7.413a.712.712,0,0,1,0-1.009.721.721,0,0,1,1.012,0l4.287,4.284a.713.713,0,0,1,.021.985l-4.305,4.314a.715.715,0,0,1-1.012-1.009Z"
                                                            transform="translate(-11.246 -6.196)"
                                                            fill="#6a6960"
                                                        />
                                                    </svg>
                                                ) : (
                                                    ""
                                                )}
                                                {e.children.length ? (
                                                    <div
                                                        className="subcat_list_fms pad-top-cat_fm br_fm"
                                                        id={`subCat${e.id}`}
                                                    >
                                                        <h3>
                                                            {/* {e.name} */}
                                                            <FormattedMessage id={e.slug} defaultMessage={e.name} />
                                                        </h3>
                                                        <div className="subcat_list_item_fms">
                                                            {e.children.map((el) => (
                                                                <div
                                                                    className="d-flex"
                                                                    onMouseEnter={() =>
                                                                        el.children.length
                                                                            ? this.handleSubCatClick(el.id)
                                                                            : ""
                                                                    }
                                                                    onMouseLeave={() => {
                                                                        if (el.children.length)
                                                                            this.leaveSubCatClick(el.id);
                                                                    }}
                                                                >
                                                                    <Link
                                                                        to={`/shop/catalog/${el.slug}`}
                                                                        onClick={this.handleChildClick}
                                                                        className="shop-castalog_fms"
                                                                    >
                                                                        <div className="sub_fms">
                                                                            <span>
                                                                                <FormattedMessage
                                                                                    id={el.slug}
                                                                                    defaultMessage={el.name}
                                                                                />
                                                                                {/* {el.name} */}
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    {el.children.length ? (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="5.718"
                                                                            height="10"
                                                                            viewBox="0 0 5.718 10"
                                                                        >
                                                                            <path
                                                                                id="Icon_ionic-ios-arrow-forward"
                                                                                data-name="Icon ionic-ios-arrow-forward"
                                                                                d="M15.241,11.194,11.456,7.413a.712.712,0,0,1,0-1.009.721.721,0,0,1,1.012,0l4.287,4.284a.713.713,0,0,1,.021.985l-4.305,4.314a.715.715,0,0,1-1.012-1.009Z"
                                                                                transform="translate(-11.246 -6.196)"
                                                                                fill="#6a6960"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {el.children.length ? (
                                                                        <div
                                                                            className="subcat-sub_list_fms pad-top-cat_fm br_fm"
                                                                            id={`subCat${el.id}`}
                                                                        >
                                                                            <h3>
                                                                                {" "}
                                                                                <FormattedMessage
                                                                                    id={el.slug}
                                                                                    defaultMessage={el.name}
                                                                                />
                                                                            </h3>

                                                                            <div className="subcat_list_item_fms subs_fms">
                                                                                {el.children.map((elem) => (
                                                                                    <div className="d-flex">
                                                                                        <Link
                                                                                            to={`/shop/catalog/${elem.slug}`}
                                                                                            onClick={
                                                                                                this.handleChildClick
                                                                                            }
                                                                                            className="shop-castalog_fms"
                                                                                        >
                                                                                            <div className="sub_fms">
                                                                                                <span>
                                                                                                    <FormattedMessage
                                                                                                        id={elem.slug}
                                                                                                        defaultMessage={
                                                                                                            elem.name
                                                                                                        }
                                                                                                    />
                                                                                                    {/* {elem.name} */}
                                                                                                </span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        );
                                    })
                                    : ""}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <button
                    type="button"
                    className="departments__button"
                    onClick={window.innerWidth < 1024 ? this.props.openMobileMenu : this.handleChildClick}
                >
                    <Menu18x14Svg className="departments__button-icon d-lg-block d-none" />
                    <MenuSvgMob className="departments__button-icon d-lg-none d-block" />
                    <div className="d-lg-block d-none">
                        <FormattedMessage id="header.variety" defaultMessage="Տեսականի" />
                    </div>
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
