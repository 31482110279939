// react
import React from "react";
import { FormattedMessage } from "react-intl";

function ProductAttributes(props) {
    const { products } = props;

    let content;
    let attrName = [];
    let attribute = [];
    if (products) {
        products.forEach((e) => {
            for (let attrDis in e.group) {
                let fill = attribute.filter((el) => el == attrDis);

                if (!fill.length) attribute.push(attrDis);
            }
        });

        products.forEach((e) => {
            attribute.forEach((el) => {
                if (e.group[el] && e.group[el].length) {
                    e.group[el].forEach((item) => {
                        if (item.length) {
                            let fill = attrName.filter((el) => el.value == item[0].admin_name);
                            if (!fill.length) attrName.push({ key: el, value: item[0].admin_name });
                        }
                    });
                }
            });
        });

        const attributeRows = (
            <>
                {attribute.length
                    ? attribute.map((el) => {
                          return (
                              <>
                                  <div className="attr-title_fm">
                                      <FormattedMessage id={el.toLowerCase().replace(/ /g, ".")} defaultMessage={el} />
                                  </div>
                                  {attrName.length
                                      ? attrName.map((es) => {
                                            return el == es.key && es.value != undefined ? (
                                                <div className="attr-information_fm">
                                                    <div>{es.value}</div>
                                                    {products.map((e) => {
                                                        return e.group && e.group[el].length && e.group[el]
                                                            ? e.group[el].map((item) => {
                                                                  return item.length &&
                                                                      item[0].admin_name == es.value ? (
                                                                      <div>{item[0].name}</div>
                                                                  ) : (
                                                                      ""
                                                                  );
                                                              })
                                                            : "";
                                                    })}
                                                </div>
                                            ) : (
                                                ""
                                            );
                                        })
                                      : ""}
                              </>
                          );
                      })
                    : ""}
            </>
        );

        content = (
            <div className="compare_fm">
                <div className="info-attr_fm">{attributeRows}</div>
            </div>
        );
    }
    return <div className="container_fm">{content}</div>;
}

export default ProductAttributes;
