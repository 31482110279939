import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../helper";
import { cartAddItemSuccessAll } from "../../store/cart";
import { cartRemoveItemSuccess } from "../../store/cart";
import { FormattedMessage } from "react-intl";

export default function Login(props) {
    const cart_token = useSelector((state) => state.cartToken.cartToken);
    const cart = useSelector((state) => state.cart);

    const history = useHistory();
    const dispatch = useDispatch();
    const intialValues = { email: "", password: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    console.log();
    const submitForm = () => {
        let option = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(`${url}/api/customer/login?token=true`, option)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.token && res.token !== "") {
                    dispatch({ type: "AUTHENTICATED", payload: true });
                    dispatch({ type: "CUSTOMER_TOKEN", payload: res.token });
                    dispatch({ type: "CUSTOMER_NAME", payload: res.data });
                    dispatch({ type: "CUSTOMER_ID", payload: res.data.id });
                    props.close("login", false);
                    props.closeModal();
                    history.push("/account/dashboard/");
                    fetch(`${url}/api/checkout/cart?token=${res.token}&api_token=${cart_token}`)
                        .then((responce) => responce.json())
                        .then((resUser) => {
                            if (resUser.data) {
                                submitCartData(resUser.data, res.token);
                            }
                        })
                        .catch((err) => console.error(err));
                } else {
                    setFormErrors({ backend: res.error });
                    console.log(res.error);
                }
            })
            .catch((err) => console.error(err));
    };

    const submitCartData = (userCart, token) => {
        let product = [];
        cart.items.map((e) => {
            product.push(e);
            dispatch(cartRemoveItemSuccess(e.id));
        });

        let filter;
        if (product.length)
            product.map((e, i) => {
                filter = userCart.items.filter((el) => el.product.id == e.product.id);
                if (!filter.length) {
                    fetch(
                        `${url}/api/checkout/cart/add/${e.product.id}?product_id=${e.product.id}&quantity=${
                            e.quantity
                        }&api_token=${cart_token}${token ? `&token=${token}` : ""}`,
                        { method: "POST" }
                    )
                        .then((responce) => responce.json())
                        .then((res) => {
                            getCart(token);
                        })
                        .catch((err) => console.error(err));
                } else {
                    getCart(token);
                }
            });
        else getCart(token);
    };

    const getCart = (token) => {
   
    
        fetch(`${url}/api/checkout/cart?token=${token}&api_token=${cart_token}`)
            .then((responce) => responce.json())
            .then((resUser) => {
                if (resUser.data) {
                    let product = [];
                    resUser.data.items.map((e) => {
                        product.push({
                            id: e.id,
                            product: e.product,
                            quantity: e.quantity,
                            price: e.product.price,
                            options: [],
                            total: Math.round(e.total),
                            itemId: e.id,
                        });
                    });
                    dispatch(
                        cartAddItemSuccessAll(
                            product,
                            Math.round(resUser.data.base_grand_total),
                            resUser.data.items.length,
                            Math.round(resUser.data.base_sub_total)
                        )
                    );
                }
            })
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormErrors(validate(formValues));
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 4) {
            errors.password = "Password must be more than 6 characters";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);
    return (
        <div className="login_content_fm">
            <div className="logn_title_fm">
                <FormattedMessage id="login" defaultMessage="Մուտք" />
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className={formErrors.email && "input-error_fm"}>
                    <label>
                        <div className="text-left">
                            <span>
                                <FormattedMessage id="login.email.address" defaultMessage="Էլ․ հասցե" />
                            </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                            className={formErrors.email ? `login_inp_fm border_red` : `login_inp_fm`}
                            type="text"
                            name="email"
                            id="email"
                            value={formValues.email}
                            onChange={handleChange}
                        />
                        <span className="login-error-fms">{formErrors.email}</span>
                    </label>
                </div>
                <div className={formErrors.password && "input-error_fm"}>
                    <label>
                        <div className="text-left">
                            <span>
                                <FormattedMessage id="topbar.password" defaultMessage="Գաղտնաբառ" />
                            </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                            className={formErrors.password ? `login_inp_fm border_red` : `login_inp_fm`}
                            type="password"
                            name="password"
                            id="password"
                            value={formValues.password}
                            onChange={handleChange}
                        />
                        <span className="login-error-fms">{formErrors.backend}</span>
                    </label>
                </div>
                <div className="forgot_pass_fm">
                    <span
                        onClick={() => {
                            props.close("login", false);
                            props.clicked("forgot", true);
                        }}
                    >
                        <FormattedMessage id="login.forgot.password" defaultMessage="Մոռացե՞լ եք գաղտնաբառը" />
                    </span>
                </div>
                <span>{formErrors.error}</span>
                <button>
                    <FormattedMessage id="login.log.in" defaultMessage="Մուտք գործել" />
                </button>
            </form>
            <div className="sign_up_fm">
                <div className="singup_link_fm">
                    <FormattedMessage id="login.dontHaveAnAccount" defaultMessage="Եթե չունեք հաշիվ, " /> {"  "}
                    <span
                        onClick={() => {
                            props.close("login", false);
                            props.clicked("reg", true);
                        }}
                        style={{ color: "#66C3D0", textDecoration: "underline" }}
                    >
                        <FormattedMessage id="login.createanAcount" defaultMessage="Գրանցվեք այստեղ։" />
                    </span>
                </div>
            </div>
        </div>
    );
}
