// react
import React, { useCallback, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import Pagination from "../shared/Pagination";
import ProductCard from "../shared/ProductCard";
import { Filters16Svg, ArrowRoundedDown12x7Svg, FilterIcon } from "../../svg";
import { sidebarOpen } from "../../store/sidebar";

import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
// import Arrow from '../../custom-svg/arrow.svg';

function useSetOption(option, filter, dispatch) {
    const callback = useCallback(filter, []);
    return useCallback(
        (data) => {
            dispatch({
                type: "SET_OPTION_VALUE",
                option,
                value: callback(data),
            });
        },
        [option, callback, dispatch]
    );
}

function ProductsView(props) {
    const {
        length,
        customer,
        isLoading,
        productsList,
        options,
        filters,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        sidebarOpen,
    } = props;
    const [layout] = useState(propsLayout);

    const handlePageChange = useSetOption("page", parseFloat, dispatch);
    const handleLimitChange = useSetOption("limit", (event) => parseFloat(event.target.value), dispatch);
    const handleSortChange = useSetOption("sort", (value) => value, dispatch);

    const handleResetFilters = useCallback(() => {
        dispatch({ type: "RESET_FILTERS" });
    }, [dispatch]);

    const filtersCount = Object.keys(filters)
        .map((x) => filters[x])
        .filter((x) => x).length;
    const arrayMeta = [];

    // arrayMeta.push(<meta name="description" content={productsList[0].short_description?productsList[0].short_description:productsList[0].description} />)
    // arrayMeta.push(<meta name="name" content={productsList[0].name} />)
    // arrayMeta.push(<meta property="og:title" content={productsList[0].name} />)
    // arrayMeta.push(<meta property="og:description" content={productsList[0].short_description?productsList[0].short_description:productsList[0].description} />)
    // arrayMeta.push( <meta property="og:image" content={ productsList[0].images.length>0? productsList[0].images[0].original_image_url:''}/>)

    const productsListItems = productsList[0]["data"].map((product, index) => {
        return (
            <div key={index} className="product_fms">
                <div className="block-products__list-item">
                    <ProductCard product={product} customer={customer} />
                </div>
            </div>
        );
    });

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    let content;

    if (productsListItems.length > 0) {
        content = (
            <div className="products-view__content">
                <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        <div className="view-options__control_fm">
                            <div className="d-block d-lg-none" onClick={() => sidebarOpen()}>
                                <FilterIcon />
                            </div>

                            <div className="sort_fms">
                                {/* <span>
                                    {" "}
                                    
                                </span> */}

                                <select
                                    className="sort-select-fms"
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        handleSortChange(e.target.value);
                                    }}
                                >
                                    <option className="view-options-sort" value="">
                                        {"Դասավորել ըստ՝"}
                                    </option>
                                    <option className="view-options-sort_fm" value="price&order=desc">
                                        Գնի նվազմանման
                                    </option>
                                    <option className="view-options-sort_fm" value="price&order=asc">
                                        {"Գնի աճման "}
                                    </option>
                                </select>
                            </div>

                            <div className="view-options-sort_fm" onClick={() => handleSortChange("price&order=asc")}>
                                {" "}
                            </div>
                            <div className="view-options-sort_fm" onClick={() => handleSortChange("price&order=desc")}>
                                {" "}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== "list" ? grid : layout}
                    data-with-features={layout === "grid-with-features" ? "true" : "false"}
                >
                    <div className="products-list__body">{productsListItems}</div>
                </div>

                <div className="products-view__pagination">
                    <Pagination
                        current={productsList[0].current_page}
                        siblings={2}
                        total={Math.ceil(+productsList[0].total / 20)}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        );
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">
                    <FormattedMessage id="noMatching" defaultMessage="Համապատասխան իրեր չկան" />{" "}
                </div>
                <div className="products-view__empty-subtitle">
                    <FormattedMessage id="resetting" defaultMessage="Փորձեք զրոյացնել զտիչները" />
                </div>
                <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
                    <FormattedMessage id="resetFilters" defaultMessage="Վերականգնել ֆիլտրերը" />
                </button>
            </div>
        );
    }

    return (
        <>
            <Helmet>{arrayMeta}</Helmet>
            <div className={rootClasses}>
                <div className="products-view__loader" />
                {content}
            </div>
        </>
    );
}

ProductsView.defaultProps = {
    layout: "grid",
    grid: "grid-3-sidebar",
    offcanvas: "mobile",
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsView);
