// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// application
import CartIndicator from "./IndicatorCart";
import Departments from "./Departments";
import Indicator from "./Indicator";
import IndicatorAccount from "./IndicatorAccount";
import IndicatorSearch from "./IndicatorSearch";
import NavLinks from "./NavLinks";
import Search from "./Search";
import { Cart120Svg, Cart20Svg, Close, Heart20Svg, LogoSmallSvg, Nav, Person20Svg, Search20Svg } from "../../svg";
import { Modal } from "reactstrap";
import LoginBlock from "../blocks/LoginBlock";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

function NavPanel(props) {
    const { layout } = props;
    let location = useLocation();
    const customer = useSelector((state) => state.customer.token);
    const compare = useSelector((state) => state.compare);
    const customerName = useSelector((state) => state.customer.customerName);
    const cart = useSelector((state) => state.cart);
    const wishlist = useSelector((state) => state.wishlist);
    let logo = null;
    let departments = null;
    let searchIndicator;
    const searchInput = React.createRef();

    useEffect(() => {
        // SetCart(state)
    }, [location, cart]);

    const [login, SetLogin] = useState(false);
    const [forgotPass, SetForgotPass] = useState(false);
    const [reg, SetReg] = useState(false);
    const [modal, SetModal] = useState(false);

    const closeModal = () => {
        SetModal(false);
        SetReg(false);
        SetLogin(false);
        SetForgotPass(false);
    };

    const clicked = (type, check) => {
        SetModal(true);
        if (type == "login") SetLogin(check);

        if (type == "reg") SetReg(check);

        if (type == "forgot") SetForgotPass(check);
    };

    const close = (type, check) => {
        clicked(type, check);
    };

    if (layout === "compact") {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/">
                    <LogoSmallSvg />
                </Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === "default") {
        departments = (
            <div className="nav-panel__departments">
                <Departments />
            </div>
        );
    }

    return (
        <>
            <Modal className="nav_modal_fm" isOpen={modal} toggle={closeModal} centered size="xl">
                <div className="quickview">
                    <button
                        className="quickview__close"
                        type="button"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        <Close />
                    </button>
                    <LoginBlock
                        login={login}
                        reg={reg}
                        clicked={clicked}
                        forgot={forgotPass}
                        close={close}
                        closeModal={closeModal}
                    />
                </div>
            </Modal>
            <div className="nav-panel">
                <div className="nav-panel__container container_fm">
                    <div className="nav-panel__row">
                        {logo}
                        {departments}

                        <div className="site-header__search d-lg-block d-none">
                            <Search context="header" />
                        </div>

                        <div className="nav-panel__indicators">
                            {searchIndicator}
                            <div className="nav-count_fm">
                                <Indicator
                                    url="/shop/compare"
                                    title={<FormattedMessage id="compare" defaultMessage="Համեմատել" />}
                                    icon={<Nav />}
                                    itemLenght={
                                        compare.length ? (
                                            <div className="nav-count-cont_fm fm_cart_compare">{compare.length}</div>
                                        ) : (
                                            ""
                                        )
                                    }
                                />
                            </div>

                            {customer != "" ? (
                                <div className="nav-count_fm">
                                    <Indicator
                                        url="/shop/wishlist"
                                        title={<FormattedMessage id="wishlist" defaultMessage="Նախընտրելի" />}
                                        value={wishlist.length}
                                        icon={<Heart20Svg />}
                                        itemLenght={
                                            wishlist.length ? (
                                                <div className="nav-count-cont_fm">{wishlist.length}</div>
                                            ) : (
                                                ""
                                            )
                                        }
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            <div
                                className="nav-count_fm"
                                onClick={() => (customer == "" ? clicked("login", true) : "")}
                            >
                                <Indicator
                                    url={customer == "" ? "/" : "/account/dashboard"}
                                    title={
                                        customerName && customerName.first_name ? (
                                            customerName.first_name
                                        ) : (
                                            <FormattedMessage id="login.log.in" defaultMessage="Մուտք" />
                                        )
                                    }
                                    icon={<Person20Svg />}
                                />
                            </div>
                            <div className="nav-count_fm fm-last_cart">
                                <Indicator
                                    url="/shop/cart"
                                    title={<FormattedMessage id="cart" defaultMessage="Զամբյուղ" />}
                                    icon={<Cart20Svg />}
                                    itemLenght={
                                        cart.items.length ? (
                                            <div className="nav-count-cont_fm fm_cart fm_cart_compare-fms">
                                                {cart.items.length}
                                            </div>
                                        ) : (
                                            ""
                                        )
                                    }
                                />
                            </div>
                            {/* <CartIndicator /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

NavPanel.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
